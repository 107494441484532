import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import course_rehab from './course_rehab';

export default combineReducers({
  alert,
  auth,
  course_rehab,
});
