import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const AdminDashboard = ({ auth: { user } }) => {

    return (
        <section className="container">
            <h1 className="large text-primary">Admin Dashboard</h1>
            <p className="lead">
                <i className="fas fa-user" /> Welcome Admin {user && user.name}
            </p>
            <Link to={`/admin_existing_course`} className="btn btn-primary my-1">
                Existing Courses
            </Link>
            <Link to={`/admin_existing_dataset`} className="btn btn-primary my-1">
                Existing Datasets
            </Link>
            <Link to={`/admin_underApproval_course`} className="btn btn-primary my-1">
                Under Approval Courses
            </Link>
            <Link to={`/admin_underApproval_dataset`} className="btn btn-primary my-1">
                Under Approval Datasets
            </Link>
            {/* <Link to={`/admin_edit_review`} className="btn btn-primary my-1">
                Edit Reviews
            </Link> */}
        </section>
    );
};

AdminDashboard.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(AdminDashboard);
