import React from 'react';
import api from '../../utils/api';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loading, Button, Rating, Panel } from "../../components/common";
import { login } from '../../actions/auth';
import { register } from '../../actions/auth';
import _ from "lodash";

const showRehabDetails = ({ isAuthenticated, login, register }) => {
    const { id } = useParams();
    const [data, setData] = useState(null)
    const [rating, setRating] = useState(5);
    const [review_text, setReviewText] = useState('');
    const [alert_message, setAlertMessage] = useState('');
    // const [btnDisabled, setBtnDisabled] = useState(true);

    const [showPanel, setShowPanel] = useState(false);

    const createNewRehabReview = async (review_body) => {
        const token = sessionStorage.getItem('tokens')
        try {
            const res = await api.post("rehab-review/create", review_body, {
                credentials: 'include',
                withCredentials: true,
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            }
            );
            window.confirm("Review Submitted. Please refresh the page to view it.");
        } catch (e) {
            console.log(e);
            if (e.response.data.code === 401 && e.response.data.message.includes('Token expired')) {
                const token = sessionStorage.getItem('tokens')
                const refreshTokens = sessionStorage.getItem('refreshTokens')

                const res = await api.post('/auth/refresh-tokens', { "refreshToken": refreshTokens }, {
                    credentials: 'include',
                    withCredentials: true,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                },
                );
                sessionStorage.setItem('tokens', res.data.access.token)
                sessionStorage.setItem('refreshTokens', res.data.refresh.token)

                try {
                    const token = sessionStorage.getItem('tokens')

                    const res = await api.post("rehab-review/create", review_body, {
                        credentials: 'include',
                        withCredentials: true,
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        },
                    }
                    );
                    window.confirm("Review Submitted. Please refresh the page to view it.");
                } catch (err) {
                    console.log("errors:", err)
                    window.confirm("Failed to Submit Review.")
                }
            } else {
                window.confirm("Error: " + e.response.data.message);
            }
        }
    }

    useEffect(() => {
        const getRehabByID = async () => {
            try {
                const res = await api.post("dashboard/rehab",
                    {
                        "rehabId": id
                    }, {});
                setData(res.data);
            } catch (e) {
                console.log(e);
            }
        }
        getRehabByID()
    }, [id]);

    if (_.isEmpty(data)) {

        return (
            <div className="background-container pt-5">
                <Loading />
            </div>
        );
    }
    else {
        let average =
            data.rehabReviews.results.reduce((accumulator, current) => {
                return accumulator + current.rating;
            }, 0) / data.rehabReviews.results.length;
        average = average.toFixed(1);

        const handleTextChange = event => {
            // if (event.target.value === '') {
            //     setBtnDisabled(true);
            //     setAlertMessage(null);
            // } else if (event.target.value !== '' && event.target.value.trim().length <= 2) {
            //     setAlertMessage('Review must be at least 3 characters');
            //     setBtnDisabled(true);
            // } else {
            //     setAlertMessage(null);
            //     setBtnDisabled(false);
            // }
            setReviewText(event.target.value);
        };


        const handleSubmit = event => {
            event.preventDefault();
            if (isAuthenticated) {
                const newReview = {
                    "rating": rating,
                    "rehab": id,
                    "comments": review_text
                };

                createNewRehabReview(newReview);
            }
            else {
                togglePanelShow();
            }
        };

        const togglePanelShow = () => {
            setShowPanel(!showPanel);
        };

        return (
            <div className='detail-container'>
                <div className='area-title'>Dataset Information</div>
                <table className='table'>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                Basic Information
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Rehab Name:
                            </td>
                            <td>
                                <a href={data.rehab.dataDetails.primaryWebsite} target="_blank" rel="noopener noreferrer">
                                    {data.rehab.basicInformation.datasetName}
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Acronym:
                            </td>
                            <td>
                                {data.rehab.basicInformation.datasetAcronym}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Summary:
                            </td>
                            <td>
                                {data.rehab.basicInformation.summary}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Study Design:
                            </td>
                            <td>
                                {data.rehab.basicInformation.studyDesign}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Keyterms:
                            </td>
                            <td>
                                {data.rehab.basicInformation.keyTerms.join(", ")}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Data Types:
                            </td>
                            <td>
                                {data.rehab.basicInformation.dataTypes.join(", ")}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Sponsoring Agency:
                            </td>
                            <td>
                                {data.rehab.basicInformation.sponsoringAgency}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                Health Measures
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Health Conditions:
                            </td>
                            <td>
                                {data.rehab.healthMeasures.healthConditions.join(", ")}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Measures:
                            </td>
                            <td>
                                {data.rehab.healthMeasures.measures.join(", ")}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Topics:
                            </td>
                            <td>
                                {data.rehab.healthMeasures.topics.join(", ")}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                Sample
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Sample Population:
                            </td>
                            <td>
                                {data.rehab.sample.samplePopulation}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Sample Notes:
                            </td>
                            <td>
                                {data.rehab.sample.sampleNotes}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Unit Of Observation:
                            </td>
                            <td>
                                {data.rehab.sample.unitOfObservation}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Countries:
                            </td>
                            <td>
                                {data.rehab.sample.countries.join(", ")}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Geographic Coverage:
                            </td>
                            <td>
                                {data.rehab.sample.geographicCoverage}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Geographic Specificity:
                            </td>
                            <td>
                                {data.rehab.sample.geographicSpecificity}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Special Populations:
                            </td>
                            <td>
                                {data.rehab.sample.specialPopulations}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                Data Collection
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Data Collection Mode:
                            </td>
                            <td>
                                {data.rehab.dataCollection.dataCollectionMode}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                From Years Collected:
                            </td>
                            <td>
                                {data.rehab.dataCollection.fromYearsCollected}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                To Years Collected:
                            </td>
                            <td>
                                {data.rehab.dataCollection.toYearsCollected}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Data Collection Frequency:
                            </td>
                            <td>
                                {data.rehab.dataCollection.dataCollectionFrequency}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                Data Details
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Summary Reports:
                            </td>
                            <td>
                                {data.rehab.dataDetails.summaryReports}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Data Components:
                            </td>
                            <td>
                                {data.rehab.dataDetails.dataComponents}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Related Datasets:
                            </td>
                            <td>
                                {data.rehab.dataDetails.relatedDatasets.join(", ")}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                Selected Papers
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                Other Papers:
                            </td>
                        </tr>
                        {data.rehab.selectedPapers.otherPapers.map(otherPapers => {
                            return (
                                <tr>
                                    <td>
                                        <a href={otherPapers.paperLink} target="_blank" rel="noopener noreferrer">
                                            link
                                        </a>
                                    </td>
                                    {otherPapers.citation !== "" ?
                                        <td>
                                            citation: {otherPapers.citation}
                                        </td>
                                        :
                                        <td>citation:</td>
                                    }
                                </tr>
                            )
                        })
                        }

                        <tr>
                            <td colSpan={2}>
                                Technical Papers:
                            </td>
                        </tr>
                        {data.rehab.selectedPapers.technicalPapers.map(technicalPapers => {
                            return (
                                <tr>
                                    <td>
                                        <a href={technicalPapers.paperLink} target="_blank" rel="noopener noreferrer">
                                            link
                                        </a>
                                    </td>
                                    {technicalPapers.citation !== "" ?
                                        <td>
                                            citation: {technicalPapers.citation}
                                        </td>
                                        :
                                        <td>citation:</td>
                                    }
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
                <div className='area-title'>Reviews</div>
                <div className="review-card">
                    <form onSubmit={handleSubmit}>
                        <h3>How would you rate this dataset?</h3>
                        <Rating select={rating => setRating(rating)} />
                        <div className="input-group">
                            <textarea placeholder="Write a review" onChange={handleTextChange}></textarea>
                            {/* <Button disabled={btnDisabled} type="submit" label="Send" /> */}
                            <Button type="submit" label="Send" />
                        </div>
                        <div className="alert_message">{!!alert_message && <>{alert_message}</>}</div>
                    </form>
                    {!!showPanel && <Panel register={register} login={login} togglePanelShow={togglePanelShow} />}
                </div>
                <div className="feedback-stats">
                    <h4>{!!data.rehabReviews.results.length ? `${data.rehabReviews.results.length}` : "0"}
                        {"\u00a0"} Dataset Reviews</h4>
                    <h4>Average Dataset Rating: {isNaN(average) ? 0 : average}</h4>
                </div>

                {
                    !!data.rehabReviews.results.length ? (
                        <div className='review-grid'>
                            {data.rehabReviews.results.map(review => {
                                return (
                                    <div className="resource-item">
                                        <div className="review-card">
                                            <div className="feedback-user">
                                                <h4>{review.user.name}</h4>
                                                <h4>Rating: {review.rating.toFixed(1)}</h4>
                                            </div>
                                            {!!review.comments && <p className='comment-left-margin'>{review.comments}</p>}
                                        </div>
                                    </div>
                                )
                            })
                            }</div>
                    ) : (
                        <h2 className="text-white">No reviews yet</h2>
                    )
                }
                <br />
            </div >
        )
    }
}


showRehabDetails.propTypes = {
    isAuthenticated: PropTypes.bool,
    login: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login, register })(showRehabDetails);