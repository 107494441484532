import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import PropTypes from 'prop-types';
import api from '../../utils/api';
import { useParams } from 'react-router';
import { Loading } from "../common";
import _ from "lodash";

const validator = require('validator');

const AdminEditCourses = ({ setAlert }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [getCourse, setGetCourse] = useState(false);
    const [courseFormData, setCourseFormData] = useState({
        name: '',
        level: '',
        websiteLink: '',
        platform: '',
        keywords: [],
        audience: '',
        courseLengthScale: '',
        courseLengthValue: 0,
        researchApplication: [],
        fields: [],
    });
    const { name, level, websiteLink, platform, keywords, audience,
        courseLengthScale, courseLengthValue, researchApplication, fields } = courseFormData;



    const editCourse = async () => {
        const token = sessionStorage.getItem('tokens')
        try {
            const res = await api.put(`/course?courseId=${id}`, courseFormData, {
                credentials: 'include',
                withCredentials: true,
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            }
            );
            window.confirm("Course Edited.")
        } catch (e) {
            console.log(e);
            if (e.response.data.code === 401 && e.response.data.message.includes('Token expired')) {
                const token = sessionStorage.getItem('tokens')
                const refreshTokens = sessionStorage.getItem('refreshTokens')

                const res = await api.post('/auth/refresh-tokens', { "refreshToken": refreshTokens }, {
                    credentials: 'include',
                    withCredentials: true,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                },
                );
                sessionStorage.setItem('tokens', res.data.access.token)
                sessionStorage.setItem('refreshTokens', res.data.refresh.token)

                try {
                    const token = sessionStorage.getItem('tokens')

                    const res = await api.put(`/course?courseId=${id}`, courseFormData, {
                        credentials: 'include',
                        withCredentials: true,
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        },
                    }
                    );
                    window.confirm("Course Edited")
                } catch (err) {
                    console.log("errors:", err)
                    window.confirm("Failed to Edit the Course.")
                }
            } else {
                window.confirm("Error: " + e.response.data.message);
            }
        }
    }

    const onChange_Course = (e) => {
        if (e.target.name === 'keywords' || e.target.name === 'researchApplication' || e.target.name === 'fields') {
            let value = e.target.value;
            setCourseFormData({ ...courseFormData, [e.target.name]: value.replace(/ /g, '').split(",") });
        }
        else {
            setCourseFormData({ ...courseFormData, [e.target.name]: e.target.value });
        }
    }


    const onSubmit_Course = (e) => {
        e.preventDefault();
        if (courseFormData.name === null || courseFormData.name === '') {
            setAlert('Name cannot be null', 'danger')
        }
        if (courseFormData.websiteLink === null || courseFormData.websiteLink === '') {
            setAlert('Website Link cannot be null', 'danger')
        }
        if (courseFormData.keywords === null || courseFormData.keywords === '') {
            setAlert('Keywords cannot be null', 'danger')
        }
        if (courseFormData.name.length > 255) {
            setAlert('Name must contain no more than 255 characters', 'danger')
        }
        if (courseFormData.platform.length > 255) {
            setAlert('Platform must contain no more than 255 characters', 'danger')
        }
        if (!validator.isURL(courseFormData.websiteLink)) {
            setAlert('Invalid website URL', 'danger');
        }
        else {
            editCourse();
        }
    };

    useEffect(() => {
        const getCourseByID = async () => {
            try {
                const res = await api.post("dashboard/course",
                    {
                        "courseId": id
                    }, {});
                setCourseFormData({
                    ...courseFormData,
                    name: res.data.course.name,
                    level: res.data.course.level,
                    websiteLink: res.data.course.websiteLink,
                    platform: res.data.course.platform,
                    keywords: res.data.course.keywords,
                    audience: res.data.course.audience,
                    courseLengthScale: res.data.course.courseLengthScale,
                    courseLengthValue: res.data.course.courseLengthValue,
                    researchApplication: res.data.course.researchApplication,
                    fields: res.data.course.fields,
                });
                setGetCourse(true);
            } catch (e) {
                console.log(e);
            }
        }
        getCourseByID()
    }, [id]);


    if (_.isEqual(getCourse, false)) {

        return (
            <div className="background-container pt-5">
                <Loading />
            </div>
        );
    }

    return (
        <section className="container">
            <h1 className="large text-primary">Edit Courses</h1>
            <h3> Please fill in the required fields with * and use "," to separate a list of words</h3>

            <form className="form" onSubmit={onSubmit_Course}>
                <h4 className='top-dis'>Name: *</h4>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Name *"
                        name="name"
                        value={name}
                        onChange={onChange_Course}
                    />
                </div>
                <div className="form-group">
                    <h4>Level (beginner/intermediate/advanced/first_time_user): *</h4>
                    <input
                        type="text"
                        placeholder="Level *"
                        name="level"
                        value={level}
                        onChange={onChange_Course}
                    />
                </div>
                <div className="form-group">
                    <h4>Website Link: *</h4>
                    <input
                        type="text"
                        placeholder="Website Link *"
                        name="websiteLink"
                        value={websiteLink}
                        onChange={onChange_Course}
                    />
                </div>
                <div className="form-group">
                    <h4>Platform:</h4>
                    <input
                        type="text"
                        placeholder="Platform"
                        name="platform"
                        value={platform}
                        onChange={onChange_Course}
                    />
                </div>
                <div className="form-group">
                    <h4>Keywords: *</h4>
                    <input
                        type="text"
                        placeholder="Keywords *"
                        name="keywords"
                        value={keywords}
                        onChange={onChange_Course}
                    />
                </div>
                <div className="form-group">
                    <h4>Audience:</h4>
                    <input
                        type="text"
                        placeholder="Audience"
                        name="audience"
                        value={audience}
                        onChange={onChange_Course}
                    />
                </div>
                <div className="form-group">
                    <h4>Course Length:</h4>
                    <input
                        type="text"
                        placeholder="Course Length"
                        name="courseLengthValue"
                        value={courseLengthValue}
                        onChange={onChange_Course}
                    />
                </div>
                <div className="form-group">
                    <h4>Course Length Scale (hours/months):</h4>
                    <input
                        type="text"
                        placeholder="Course Length Scale"
                        name="courseLengthScale"
                        value={courseLengthScale}
                        onChange={onChange_Course}
                    />
                </div>
                <div className="form-group">
                    <h4>Research Application:</h4>
                    <input
                        type="text"
                        placeholder="Research Application"
                        name="researchApplication"
                        value={researchApplication}
                        onChange={onChange_Course}
                    />
                </div>
                <div className="form-group">
                    <h4>Fields:</h4>
                    <input
                        type="text"
                        placeholder="Fields"
                        name="fields"
                        value={fields}
                        onChange={onChange_Course}
                    />
                </div>
                <input type="submit" className="btn btn-primary" value="Submit" />
            </form>
            <button className="top-dis btn btn-primary" onClick={() => navigate(-1)}>Cancel</button>
        </section>
    );
};

AdminEditCourses.propTypes = {
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { setAlert })(AdminEditCourses);

