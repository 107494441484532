import {
    GET_SEARCH_COURSES_ERROR,
    GET_SEARCH_REHABS_ERROR
} from "../actions/types";

const initialState = {
    courses: null,
    rehabs: null,
    error: null,
};

function course_rehabReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SEARCH_COURSES_ERROR:
        case GET_SEARCH_REHABS_ERROR:
            return {
                ...state,
                error: action.error,
            };


        default:
            return state;
    }
}

export default course_rehabReducer;
