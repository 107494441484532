import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from '../actions/types';

const initialState = {
  tokens: sessionStorage.getItem('tokens'),
  refreshTokens: sessionStorage.getItem('refreshTokens'),
  isAuthenticated: sessionStorage.getItem('tokens') ? true : false,
  loading: false,
  user: JSON.parse(sessionStorage.getItem('users'))
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      sessionStorage.setItem('tokens', payload.tokens.access.token)
      sessionStorage.setItem('refreshTokens', payload.tokens.refresh.token)
      sessionStorage.setItem('users', JSON.stringify(payload.user))
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        //user: payload.user
      };
    case AUTH_ERROR:
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case LOGOUT:
      sessionStorage.removeItem('tokens')
      sessionStorage.removeItem('refreshTokens')
      sessionStorage.removeItem('users')
      return {
        ...state,
        tokens: null,
        isAuthenticated: false,
        loading: false,
        user: null
      };

    default:
      return state;
  }
}

export default authReducer;
