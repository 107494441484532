import React from 'react';
import api from '../../utils/api';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loading } from "../../components/common";
import { Link } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import _ from "lodash";


const EditReview = ({ setAlert }) => {
    const { id } = useParams();
    const [courseReviewData, setCourseReviewData] = useState(null);
    const [rehabReviewData, setRehabReviewData] = useState(null);
    const [rating, setRating] = useState(5);
    const [review_text, setReviewText] = useState('');

    const [showCourseReview, setShowCourseReview] = useState(true);
    const [showEditState, setShowEditState] = useState(false);
    const [editReviewID, setEditReviewID] = useState('');

    const switchCourseReview = () => {
        setShowEditState(false);
        setShowCourseReview(true);
    };
    const switchRehabReview = () => {
        setShowEditState(false);
        setShowCourseReview(false);
    };

    const switchEditState = (review_item) => {
        setShowEditState(!showEditState);
        setEditReviewID(review_item.id);
        setRating(review_item.rating);
        setReviewText(review_item.comments);
    };


    const deleteCourseReview = async (review_item) => {
        if (window.confirm('Delete the review?')) {
            const token = sessionStorage.getItem('tokens')
            try {
                const res = await api.delete("/course-review", {
                    credentials: 'include',
                    withCredentials: true,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                    data: {
                        "courseReviewId": review_item.id
                    }
                }
                );
                window.confirm("Review Deleted. Please refresh the page to view.")
            } catch (e) {
                console.log(e);
                if (e.response.data.code === 401 && e.response.data.message.includes('Token expired')) {
                    const token = sessionStorage.getItem('tokens')
                    const refreshTokens = sessionStorage.getItem('refreshTokens')

                    const res = await api.post('/auth/refresh-tokens', { "refreshToken": refreshTokens }, {
                        credentials: 'include',
                        withCredentials: true,
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        },
                    },
                    );
                    sessionStorage.setItem('tokens', res.data.access.token)
                    sessionStorage.setItem('refreshTokens', res.data.refresh.token)

                    try {
                        const token = sessionStorage.getItem('tokens')
                        const res = await api.delete("/course-review", {
                            credentials: 'include',
                            withCredentials: true,
                            headers: {
                                "Authorization": `Bearer ${token}`,
                            },
                            data: {
                                "courseReviewId": review_item.id
                            }
                        }
                        );
                        window.confirm("Review Deleted. Please refresh the page to view.")
                    } catch (err) {
                        console.log("errors:", err)
                        window.confirm("Failed to Delete Review.")
                    }
                } else {
                    window.confirm("Error: " + e.response.data.message);
                }
            }
        }
    }

    const deleteRehabReview = async (review_item) => {
        if (window.confirm('Delete the review?')) {
            const token = sessionStorage.getItem('tokens')
            try {
                const res = await api.delete("/rehab-review", {
                    credentials: 'include',
                    withCredentials: true,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                    data: {
                        "rehabReviewId": review_item.id
                    }
                }
                );
                window.confirm("Review Deleted. Please refresh the page to view.")
            } catch (e) {
                console.log(e);
                if (e.response.data.code === 401 && e.response.data.message.includes('Token expired')) {
                    const token = sessionStorage.getItem('tokens')
                    const refreshTokens = sessionStorage.getItem('refreshTokens')

                    const res = await api.post('/auth/refresh-tokens', { "refreshToken": refreshTokens }, {
                        credentials: 'include',
                        withCredentials: true,
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        },
                    },
                    );
                    sessionStorage.setItem('tokens', res.data.access.token)
                    sessionStorage.setItem('refreshTokens', res.data.refresh.token)

                    try {
                        const token = sessionStorage.getItem('tokens')
                        const res = await api.delete("/rehab-review", {
                            credentials: 'include',
                            withCredentials: true,
                            headers: {
                                "Authorization": `Bearer ${token}`,
                            },
                            data: {
                                "rehabReviewId": review_item.id
                            }
                        }
                        );
                        window.confirm("Review Deleted. Please refresh the page to view.")
                    } catch (err) {
                        console.log("errors:", err)
                        window.confirm("Failed to Delete Review.")
                    }
                } else {
                    window.confirm("Error: " + e.response.data.message);
                }
            }
        }
    }

    const editCourseReview = async (review_item, review_body) => {
        const token = sessionStorage.getItem('tokens')
        try {
            const res = await api.put(`/course-review?courseReviewId=${review_item.id}`, review_body, {
                credentials: 'include',
                withCredentials: true,
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            }
            );
            window.confirm("Review Changed. Please refresh the page to view it.")
        } catch (e) {
            console.log(e);
            if (e.response.data.code === 401 && e.response.data.message.includes('Token expired')) {
                const token = sessionStorage.getItem('tokens')
                const refreshTokens = sessionStorage.getItem('refreshTokens')

                const res = await api.post('/auth/refresh-tokens', { "refreshToken": refreshTokens }, {
                    credentials: 'include',
                    withCredentials: true,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                },
                );
                sessionStorage.setItem('tokens', res.data.access.token)
                sessionStorage.setItem('refreshTokens', res.data.refresh.token)

                try {
                    const token = sessionStorage.getItem('tokens')
                    const res = await api.put(`/course-review?courseReviewId=${review_item.id}`, review_body, {
                        credentials: 'include',
                        withCredentials: true,
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        },
                    }
                    );
                    window.confirm("Review Changed. Please refresh the page to view it.")
                } catch (err) {
                    console.log("errors:", err)
                    window.confirm("Failed to Edit Review.")
                }
            } else {
                window.confirm("Error: " + e.response.data.message);
            }
        }
    }

    const editRehabReview = async (review_item, review_body) => {
        const token = sessionStorage.getItem('tokens')
        try {
            const res = await api.put(`/rehab-review?rehabReviewId=${review_item.id}`, review_body, {
                credentials: 'include',
                withCredentials: true,
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            }
            );
            window.confirm("Review Changed. Please refresh the page to view it.")
        } catch (e) {
            console.log(e);
            if (e.response.data.code === 401 && e.response.data.message.includes('Token expired')) {
                const token = sessionStorage.getItem('tokens')
                const refreshTokens = sessionStorage.getItem('refreshTokens')

                const res = await api.post('/auth/refresh-tokens', { "refreshToken": refreshTokens }, {
                    credentials: 'include',
                    withCredentials: true,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                },
                );
                sessionStorage.setItem('tokens', res.data.access.token)
                sessionStorage.setItem('refreshTokens', res.data.refresh.token)

                try {
                    const token = sessionStorage.getItem('tokens')
                    const res = await api.put(`/rehab-review?rehabReviewId=${review_item.id}`, review_body, {
                        credentials: 'include',
                        withCredentials: true,
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        },
                    }
                    );
                    window.confirm("Review Changed. Please refresh the page to view it.")
                } catch (err) {
                    console.log("errors:", err)
                    window.confirm("Failed to Edit Review.")
                }
            } else {
                window.confirm("Error: " + e.response.data.message);
            }
        }
    }


    const handleTextChange = event => {
        setReviewText(event.target.value);
    };

    const handleRatingChange = event => {
        setRating(event.target.value);
    };
    const handleSubmit = (review_item) => event => {
        event.preventDefault();
        setShowEditState(false);
        const review_body = {
            "comments": review_text,
            "rating": rating
        }
        if (rating === null || rating === '') {
            setAlert('Rating cannot be null', 'danger')
        }
        if (rating !== 1 && rating !== 2 && rating !== 3 && rating !== '4' && rating !== 5 &&
            rating !== 1.0 && rating !== 2.0 && rating !== 3.0 && rating !== 4.0 && rating !== 5.0) {
            setAlert('Rating can only be an integer from 1 to 5', 'danger')
        }
        else {
            if (showCourseReview) {
                editCourseReview(review_item, review_body);
            }
            else {
                editRehabReview(review_item, review_body);
            }
        }

    };


    const getCourseReviewByID = async () => {
        switchCourseReview();
        try {
            const res = await api.post("/course-review",
                {
                    "user": id
                }, {});
            setCourseReviewData(res.data);
        } catch (e) {
            console.log(e);
        }
    }

    const getRehabReviewByID = async () => {
        switchRehabReview();
        try {
            const res = await api.post("/rehab-review",
                {
                    "user": id
                }, {});
            setRehabReviewData(res.data);
            console.log("rehab review:", rehabReviewData)
        } catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getCourseReviewByID()
    }, [id]);


    if (showCourseReview && _.isEmpty(courseReviewData)) {

        return (
            <div className="background-container pt-5">
                <Loading />
            </div>
        );
    }
    if (!showCourseReview && _.isEmpty(rehabReviewData)) {

        return (
            <div className="background-container pt-5">
                <Loading />
            </div>
        );
    }


    return (
        <div className='detail-container'>
            <input type="submit" className="btn btn-primary" value="Course" onClick={() => getCourseReviewByID()} />
            <input type="submit" className="left-margin btn btn-primary" value="Dataset" onClick={() => getRehabReviewByID()} />
            {showCourseReview ? (!!courseReviewData.results.length ? (
                <div className='review-grid'>
                    {courseReviewData.results.map(review => {
                        if (!showEditState || (showEditState && review.id !== editReviewID)) {
                            return (
                                <div className="resource-item">
                                    <div className="review-card">
                                        <div className="feedback-user">
                                            <Link to={`/course/${review.course.id}`}>
                                                {review.course.name}
                                            </Link>
                                            <h4>Rating: {review.rating.toFixed(1)}</h4>
                                        </div>
                                        <div className="edit-button-layout">
                                            <p className='comment-left-margin'>{review.comments}</p>
                                            <div>
                                                <button onClick={() => switchEditState(review)}>Edit</button>
                                                <button onClick={() => deleteCourseReview(review)}>Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    <form className="form" onSubmit={handleSubmit(review)}>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Rating"
                                                name="rating"
                                                value={rating}
                                                onChange={handleRatingChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Comments"
                                                name="review_text"
                                                value={review_text}
                                                onChange={handleTextChange}
                                            />
                                        </div>
                                        <input type="submit" className="btn btn-primary" value="Submit" />
                                        <input type="submit" className="btn btn-primary" value="Cancel" onClick={() => switchEditState(review)} />
                                    </form>
                                </div>
                            )
                        }
                    })
                    }</div>
            ) : (
                <h2 className="text-white">You have no course reviews yet</h2>
            )
            ) : (
                !!rehabReviewData.results.length ? (
                    <div className='review-grid'>
                        {rehabReviewData.results.map(review => {
                            if (!showEditState || (showEditState && review.id !== editReviewID)) {
                                return (
                                    <div className="resource-item">
                                        <div className="review-card">
                                            <div className="feedback-user">
                                                <Link to={`/rehab/${review.rehab.id}`}>
                                                    {review.rehab.basicInformation.datasetName}
                                                </Link>
                                                <h4>Rating: {review.rating.toFixed(1)}</h4>
                                            </div>
                                            <div className="edit-button-layout">
                                                <p className='comment-left-margin'>{review.comments}</p>
                                                <div>
                                                    <button onClick={() => switchEditState(review)}>Edit</button>
                                                    <button onClick={() => deleteRehabReview(review)}>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div>
                                        <form className="form" onSubmit={handleSubmit(review)}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    placeholder="Rating"
                                                    name="rating"
                                                    value={rating}
                                                    onChange={handleRatingChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    placeholder="Comments"
                                                    name="review_text"
                                                    value={review_text}
                                                    onChange={handleTextChange}
                                                />
                                            </div>
                                            <input type="submit" className="btn btn-primary" value="Submit" />
                                            <input type="submit" className="btn btn-primary" value="Cancel" onClick={() => switchEditState(review)} />
                                        </form>
                                    </div>
                                )
                            }
                        })
                        }</div>
                ) : (
                    <h2 className="text-white">You have no dataset reviews yet</h2>
                )
            )}
        </div>
    )
}

EditReview.propTypes = {
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});


export default connect(mapStateToProps, { setAlert })(EditReview);