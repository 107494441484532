import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getCourses, getRehabs } from "../../actions/course_rehab";

import _ from "lodash";

import { Pagination } from "..";
import { Loading } from "../../components/common";

import CourseCard from "../ResourceCard/Course";
import RehabCard from "../ResourceCard/Rehab";



const Landing = ({ getCourses, getRehabs }) => {

  const sorting = {
    mostRelevance: "most_relevance",
    AToZ: "a_z_alphabetically",
    ZToA: "z_a_alphabetically",
    increasingCourseLength: "increasing_course_length",
    decreasingCourseLength: "decreasing_course_length",
  }

  const filter = {
    // all: "all",
    course: "course",
    rehab: "rehab"
  }

  const filteringInitialState = {
    sorting: "",
    filtering: "course",
    paging: 1
  }

  const [searchResults, setSearchResults] = useState(null);
  const [courseTotalCount, setCourseTotalCount] = useState(0);
  const [rehabTotalCount, setRehabTotalCount] = useState(0);

  const [displayResults, setDisplayResults] = useState(null);  //searchresult->resourresult to show

  const [selectedSortingValue, setSelectedSortingValue] = useState(filteringInitialState.sorting);
  const [selectedFilteringValue, setSelectedFilteringValue] = useState(filteringInitialState.filtering);
  const [selectedPage, setSelectedPage] = useState(filteringInitialState.paging);

  const [showCourseLengthSort, setShowCourseLengthSort] = useState(true);

  const [formData, setFormData] = useState({
    pageSize: 30,
    searchText: "",
    itemsCount: 0,
    // course_totalPages: 0,
    // rehab_totalPages: 0,
    totalPages: 0
  });

  useEffect(() => {
    const waitResources = async () => {
      let res_course = await getCourses("", 30, selectedPage);
      let res_rehab = await getRehabs("", 30, selectedPage);
      setCourseTotalCount(res_course.data.totalResults);
      setRehabTotalCount(res_rehab.data.totalResults);
      combineAllResults(res_course.data, res_rehab.data);
    }
    waitResources();
  }, []);

  const onChange = e => {
    setFormData({ ...formData, searchText: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setSelectedPage(filteringInitialState.paging);
    if (formData.searchText !== "") {
      setSelectedSortingValue(sorting.mostRelevance);
    }
    else {
      setSelectedSortingValue(filteringInitialState.sorting);
    }
    const waitFilterResources = async () => {
      let res_course;
      let res_rehab;

      res_course = await getCourses(formData.searchText, 30, 1);
      res_rehab = await getRehabs(formData.searchText, 30, 1);

      combineAllResults(res_course.data, res_rehab.data);
      setCourseTotalCount(res_course.data.totalResults ? res_course.data.totalResults : 0);
      setRehabTotalCount(res_rehab.data.totalResults ? res_rehab.data.totalResults : 0);
    }
    waitFilterResources();

  };

  const onPageChange = (page) => {
    setSelectedPage(page);
    const waitFilterResources = async () => {
      if (selectedFilteringValue === 'course') {
        let res_course = await getCourses(formData.searchText, 30, page);
        let res_rehab = await getRehabs(formData.searchText, 30, 1);
        setCourseTotalCount(res_course.data.totalResults ? res_course.data.totalResults : 0);
        setRehabTotalCount(res_rehab.data.totalResults ? res_rehab.data.totalResults : 0);
        combineAllResults(res_course.data, res_rehab.data);
      }
      else if (selectedFilteringValue === 'rehab') {
        let res_course = await getCourses(formData.searchText, 30, 1);
        let res_rehab = await getRehabs(formData.searchText, 30, page);
        setCourseTotalCount(res_course.data.totalResults ? res_course.data.totalResults : 0);
        setRehabTotalCount(res_rehab.data.totalResults ? res_rehab.data.totalResults : 0);
        combineAllResults(res_course.data, res_rehab.data);
      }
    }
    waitFilterResources();
    if (formData.searchText !== "") {
      setSelectedSortingValue(sorting.mostRelevance);
    }
    else {
      setSelectedSortingValue(filteringInitialState.sorting);
    }
  };


  const combineAllResults = (courses, rehabs) => {
    let allresults = [];
    // let totalResults = 0
    if (courses) {
      for (var i = 0; i < courses.results.length; i++) {
        allresults.push(courses.results[i]);
      }
      // totalResults += courses.totalResults
      // setFormData({ ...formData, course_totalPages: courses.totalPages });
    }
    if (rehabs) {
      for (var i = 0; i < rehabs.results.length; i++) {
        allresults.push(rehabs.results[i]);
      }
      // totalResults += rehabs.totalResults
      // setFormData({ ...formData, rehab_totalPages: rehabs.totalPages });
    }
    setSearchResults(allresults);
    // setFormData({ ...formData, itemsCount: totalResults });
    if (selectedFilteringValue === 'course') {
      setDisplayResults(courses.results);
      setFormData({ ...formData, totalPages: (courses.totalPages ? courses.totalPages : 1) });
      setFormData({ ...formData, itemsCount: (courses.totalResults ? courses.totalResults : 0) });
      setCourseTotalCount(courses.totalResults ? courses.totalResults : 0);
    }
    else {
      setDisplayResults(rehabs.results);
      setFormData({ ...formData, totalPages: (rehabs.totalPages ? rehabs.totalPages : 1) });
      setFormData({ ...formData, itemsCount: (rehabs.totalResults ? rehabs.totalResults : 0) });
      setRehabTotalCount(rehabs.totalResults ? rehabs.totalResults : 0);
    }

    if (formData.searchText !== "") {
      setCourseTotalCount(courses.totalResults ? courses.totalResults : 0);
      setRehabTotalCount(rehabs.totalResults ? rehabs.totalResults : 0);
    }
  }


  const getName = (resourceItem) => {
    if (resourceItem.category === "course") {
      return resourceItem.name;
    }
    else if (resourceItem.category === "dataset") {
      return resourceItem.basicInformation.datasetName;
    }
    else {
      return "";
    }
  }
  const filterResults = (param) => {
    setShowCourseLengthSort(false);
    if (param.sorting) {
      setSelectedSortingValue(param.sorting);
    }
    if (param.filtering) {
      setSelectedFilteringValue(param.filtering);
    }
    if (param.paging) {
      setSelectedPage(param.paging);
    }
    let sortParam = param.sorting || selectedSortingValue;
    let filterParam = param.filtering || selectedFilteringValue;
    let pageParam = param.paging || selectedPage;

    // 1. do filtering based on searchResult
    // 2. do sorting based on result 1
    // 3. filtering results on specific page on paging
    // 4. set filtering results to displayResults
    let newResult = [...searchResults]
    if (filterParam === filter.course) {
      newResult = newResult.filter(resource => resource.category === "course");

      setFormData({ ...formData, itemsCount: courseTotalCount });
      setShowCourseLengthSort(true);
      if (sortParam === sorting.increasingCourseLength) {
        newResult.sort((a, b) => a.courseLengthTotalHours - b.courseLengthTotalHours)
      } else if (sortParam === sorting.decreasingCourseLength) {
        newResult.sort((a, b) => b.courseLengthTotalHours - a.courseLengthTotalHours)
      }
    }
    else if (filterParam === filter.rehab) {
      setFormData({ ...formData, itemsCount: rehabTotalCount });
      newResult = newResult.filter(resource => resource.category === "dataset");
    }
    if (sortParam === sorting.mostRelevance) {
      if (formData.searchText !== "") {
        newResult.sort((a, b) => b.score - a.score)
      }
    }
    else if (sortParam === sorting.AToZ) {
      newResult.sort((a, b) => {
        let name1 = getName(a);
        let name2 = getName(b);
        return name1.localeCompare(name2);
      })
    }
    else if (sortParam === sorting.ZToA) {
      newResult.sort((a, b) => {
        let name1 = getName(a);
        let name2 = getName(b);
        return name2.localeCompare(name1);
      })
    }
    setDisplayResults(newResult);
  }

  if (_.isNull(displayResults)) {

    return (
      <div className="background-container pt-5">
        <Loading />
      </div>
    );
  }
  // console.log("course:", course.results)
  // console.log("rehab:", rehab.results)
  // console.log("search courses:", courses)
  // console.log("search rehabs:", rehabs)

  return (
    <div className="background-container">
      <div className="mx-5 py-5">
        <div className="row">
          <div>
            <h4 className="text-muted text-left p-1">Sorting</h4>
            <div>
              <a className={"sorting-link " + (selectedSortingValue === 'most_relevance' ? 'active-sorting-link' : '')} onClick={() => filterResults({ sorting: "most_relevance" })}>Most Relevance</a>
            </div>
            <div>
              <a className={"sorting-link " + (selectedSortingValue === 'a_z_alphabetically' ? 'active-sorting-link' : '')} onClick={() => filterResults({ sorting: "a_z_alphabetically" })}>Alphabetically A-Z</a>
            </div>
            <div>
              <a className={"sorting-link " + (selectedSortingValue === 'z_a_alphabetically' ? 'active-sorting-link' : '')} onClick={() => filterResults({ sorting: "z_a_alphabetically" })}>Alphabetically Z-A</a>
            </div>
            {!!showCourseLengthSort &&
              (<div>
                <div>
                  <a className={"sorting-link " + (selectedSortingValue === 'increasing_course_length' ? 'active-sorting-link' : '')} onClick={() => filterResults({ sorting: "increasing_course_length" })}>Least Hours</a>
                </div>
                <div>
                  <a className={"sorting-link " + (selectedSortingValue === 'decreasing_course_length' ? 'active-sorting-link' : '')} onClick={() => filterResults({ sorting: "decreasing_course_length" })}>Most Hours</a>
                </div>
              </div>)}

            <h4 className="text-muted text-left p-1">Category</h4>
            {/* <div>
              <a className={"sorting-link " + (selectedFilteringValue === 'all' ? 'active-sorting-link' : '')} onClick={() => filterResults({ filtering: "all" })}>All</a>
            </div> */}
            <div>
              <a className={"sorting-link " + (selectedFilteringValue === 'course' ? 'active-sorting-link' : '')} onClick={() => filterResults({ filtering: "course" })}>Course</a>
            </div>
            <div>
              <a className={"sorting-link " + (selectedFilteringValue === 'rehab' ? 'active-sorting-link' : '')} onClick={() => filterResults({ filtering: "rehab" })}>Dataset</a>
            </div>
          </div>

          <div className="search-area">
            <form onSubmit={onSubmit} className="search-form-style">
              <input
                className="search-style"
                type="text"
                placeholder="Search..."
                onChange={onChange}
              />
              <input className="submit-search1 btn-primary" type="submit" value="Search" />
            </form>

            <p className="text-dark text-left text-muted ">
              {formData.itemsCount !== 0 ? `${formData.itemsCount}` : "0"}
              {"\u00a0"} resources found.
            </p>

            {!!displayResults ? (
              <div className='resource-grid'>
                {displayResults.map(resource => {
                  if (resource.category === "course") {
                    return (
                      <div className="resource-item">
                        <CourseCard course_item={resource} />
                      </div>
                    )
                  }
                  else {
                    return (
                      <div className="resource-item">
                        <RehabCard rehab_item={resource} />
                      </div>
                    )
                  }
                })
                }</div>
            ) : (
              <h1 className="text-white">No Resources</h1>
            )}
            <br />

            <Pagination
              itemsCount={formData.itemsCount}
              pageSize={formData.pageSize}
              onPageChange={onPageChange}
              currentPage={selectedPage}
            />
          </div>
        </div>
      </div>
    </div>

  );
};

Landing.propTypes = {
  getCourses: PropTypes.func.isRequired,
  getRehabs: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { getCourses, getRehabs })(Landing);
