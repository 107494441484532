import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import PropTypes from 'prop-types';
import api from '../../utils/api';
import { useParams } from 'react-router';
import { Loading } from "../../components/common";
import _ from "lodash";

const validator = require('validator');

const AdminEditDatasets = ({ setAlert }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [getDataset, setGetDataset] = useState(false);
    const [rehabFormData, setRehabFormData] = useState({
        datasetName: '',
        datasetAcronym: '',
        summary: '',
        keyTerms: [],
        studyDesign: '',
        dataTypes: [],
        sponsoringAgency: '',
        healthConditions: [],
        measures: [],
        topics: [],
        samplePopulation: '',
        sampleNotes: '',
        unitOfObservation: '',
        countries: [],
        geographicCoverage: '',
        geographicSpecificity: '',
        specialPopulations: '',
        dataCollectionMode: '',
        fromYearsCollected: 0,
        toYearsCollected: 0,
        dataCollectionFrequency: '',
        primaryWebsite: '',
        dataAccess: '',
        summaryReports: '',
        dataComponents: '',
        relatedDatasets: [],
        otherPapers_paperLink: [],
        otherPapers_citation: [],
        otherPapers: [],
        technicalPapers_paperLink: [],
        technicalPapers_citation: [],
        technicalPapers: [],
    });
    const { datasetName, datasetAcronym, summary, keyTerms, studyDesign, dataTypes,
        sponsoringAgency, healthConditions, measures, topics, samplePopulation, sampleNotes,
        unitOfObservation, countries, geographicCoverage, geographicSpecificity, specialPopulations,
        dataCollectionMode, fromYearsCollected, toYearsCollected, dataCollectionFrequency, primaryWebsite,
        dataAccess, summaryReports, dataComponents, relatedDatasets, otherPapers_paperLink, otherPapers_citation,
        technicalPapers_paperLink, technicalPapers_citation } = rehabFormData


    let rehab_body = {
        "basicInformation": {
            "datasetName": rehabFormData.datasetName,
            "datasetAcronym": rehabFormData.datasetAcronym,
            "summary": rehabFormData.summary,
            "keyTerms": rehabFormData.keyTerms,
            "studyDesign": rehabFormData.studyDesign,
            "dataTypes": rehabFormData.dataTypes,
            "sponsoringAgency": rehabFormData.sponsoringAgency
        },
        "healthMeasures": {
            "healthConditions": rehabFormData.healthConditions,
            "measures": rehabFormData.measures,
            "topics": rehabFormData.topics
        },
        "sample": {
            "samplePopulation": rehabFormData.samplePopulation,
            "sampleNotes": rehabFormData.sampleNotes,
            "unitOfObservation": rehabFormData.unitOfObservation,
            "countries": rehabFormData.countries,
            "geographicCoverage": rehabFormData.geographicCoverage,
            "geographicSpecificity": rehabFormData.geographicSpecificity,
            "specialPopulations": rehabFormData.specialPopulations
        },
        "dataCollection": {
            "dataCollectionMode": rehabFormData.dataCollectionMode,
            "fromYearsCollected": rehabFormData.fromYearsCollected,
            "toYearsCollected": rehabFormData.toYearsCollected,
            "dataCollectionFrequency": rehabFormData.dataCollectionFrequency
        },
        "dataDetails": {
            "primaryWebsite": rehabFormData.primaryWebsite,
            "dataAccess": rehabFormData.dataAccess,
            "dataAccessRequirements": "Admin rights",
            "summaryReports": rehabFormData.summaryReports,
            "dataComponents": rehabFormData.dataComponents,
            "relatedDatasets": rehabFormData.relatedDatasets
        },
        "selectedPapers": {}
    }



    const editDataset = async () => {
        const token = sessionStorage.getItem('tokens')
        try {
            const res = await api.put(`/rehab?rehabId=${id}`, rehab_body, {
                credentials: 'include',
                withCredentials: true,
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            }
            );
            window.confirm("Dataset Edited.")
        } catch (e) {
            console.log(e);
            if (e.response.data.code === 401 && e.response.data.message.includes('Token expired')) {
                const token = sessionStorage.getItem('tokens')
                const refreshTokens = sessionStorage.getItem('refreshTokens')

                const res = await api.post('/auth/refresh-tokens', { "refreshToken": refreshTokens }, {
                    credentials: 'include',
                    withCredentials: true,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                },
                );
                sessionStorage.setItem('tokens', res.data.access.token)
                sessionStorage.setItem('refreshTokens', res.data.refresh.token)

                try {
                    const token = sessionStorage.getItem('tokens')

                    const res = await api.put(`/rehab?rehabId=${id}`, rehab_body, {
                        credentials: 'include',
                        withCredentials: true,
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        },
                    }
                    );
                    window.confirm("Dataset Edited")
                } catch (err) {
                    console.log("errors:", err)
                    window.confirm("Failed to Edit the Dataset.")
                }
            } else {
                window.confirm("Error: " + e.response.data.message);
            }
        }
    }
    const waitSelectedPapers = () => {
        let otherPapersResults = [];
        let technicalPapersResults = [];
        for (var i = 0; i < rehabFormData.otherPapers_citation.length; i++) {
            otherPapersResults.push(
                {
                    "paperLink": rehabFormData.otherPapers_paperLink[i],
                    "citation": rehabFormData.otherPapers_citation[i]
                }
            );
        }
        for (var i = rehabFormData.otherPapers_citation.length; i < rehabFormData.otherPapers_paperLink.length; i++) {
            otherPapersResults.push(
                {
                    "paperLink": rehabFormData.otherPapers_paperLink[i],
                    "citation": ""
                }
            );
        }

        for (var i = 0; i < rehabFormData.technicalPapers_citation.length; i++) {
            technicalPapersResults.push(
                {
                    "paperLink": rehabFormData.technicalPapers_paperLink[i],
                    "citation": rehabFormData.technicalPapers_citation[i]
                }
            );
        }
        for (var i = rehabFormData.technicalPapers_citation.length; i < rehabFormData.technicalPapers_paperLink.length; i++) {
            technicalPapersResults.push(
                {
                    "paperLink": rehabFormData.technicalPapers_paperLink[i],
                    "citation": ""
                }
            );
        }

        return {
            "otherPapers": otherPapersResults,
            "technicalPapers": technicalPapersResults
        }
    }

    const onChange_Rehab = (e) => {
        if (e.target.name === 'keyTerms' || e.target.name === 'dataTypes' || e.target.name === 'healthConditions'
            || e.target.name === 'measures' || e.target.name === 'topics' || e.target.name === 'countries'
            || e.target.name === 'relatedDatasets' || e.target.name === 'otherPapers_paperLink'
            || e.target.name === 'otherPapers_citation' || e.target.name === 'technicalPapers_paperLink'
            || e.target.name === 'technicalPapers_citation') {
            let value = e.target.value;
            setRehabFormData({ ...rehabFormData, [e.target.name]: value.replace(/ /g, '').split(",") });
        }
        else {
            setRehabFormData({ ...rehabFormData, [e.target.name]: e.target.value });
        }
    }


    const onSubmit_Rehab = (e) => {
        e.preventDefault();
        if (rehabFormData.datasetName === null || rehabFormData.datasetName === '') {
            setAlert('Dataset Name cannot be null', 'danger')
        }
        if (rehabFormData.summary === null || rehabFormData.summary === '') {
            setAlert('Summary cannot be null', 'danger')
        }
        if (rehabFormData.keyTerms === null || rehabFormData.keyTerms === '') {
            setAlert('Key Terms cannot be null', 'danger')
        }
        if (rehabFormData.studyDesign === null || rehabFormData.studyDesign === '') {
            setAlert('Study Design cannot be null', 'danger')
        }
        if (rehabFormData.healthConditions === null || rehabFormData.healthConditions === '') {
            setAlert('Health Conditions cannot be null', 'danger')
        }
        if (rehabFormData.fromYearsCollected === null || rehabFormData.fromYearsCollected === '') {
            setAlert('From Years Collected cannot be null', 'danger')
        }
        if (rehabFormData.primaryWebsite === null || rehabFormData.primaryWebsite === '') {
            setAlert('Primary Website cannot be null', 'danger')
        }
        if (rehabFormData.dataAccess === null || rehabFormData.dataAccess === '') {
            setAlert('Data Access cannot be null', 'danger')
        }
        if (rehabFormData.otherPapers_paperLink === null || rehabFormData.otherPapers_paperLink === '') {
            setAlert('Other Papers\' PaperLinks cannot be null', 'danger')
        }
        if (rehabFormData.technicalPapers_paperLink === null || rehabFormData.technicalPapers_paperLink === '') {
            setAlert('Technical Papers\' PaperLinks cannot be null', 'danger')
        }
        if (rehabFormData.datasetName.length > 255) {
            setAlert('Dataset Name must contain no more than 255 characters', 'danger')
        }
        if (rehabFormData.datasetAcronym.length > 255) {
            setAlert('Dataset Acronym must contain no more than 255 characters', 'danger')
        }
        if (rehabFormData.sponsoringAgency.length > 255) {
            setAlert('Sponsoring Agency must contain no more than 255 characters', 'danger')
        }
        if (rehabFormData.samplePopulation.length > 255) {
            setAlert('Sample Population must contain no more than 255 characters', 'danger')
        }
        if (rehabFormData.unitOfObservation.length > 255) {
            setAlert('Unit Of Observation must contain no more than 255 characters', 'danger')
        }
        if (rehabFormData.dataCollectionMode.length > 255) {
            setAlert('Data Collection Mode must contain no more than 255 characters', 'danger')
        }
        if (rehabFormData.dataCollectionFrequency.length > 2048) {
            setAlert('Data Collection Frequency must contain no more than 2048 characters', 'danger');
        }
        if (rehabFormData.primaryWebsite.length > 255) {
            setAlert('Primary Website must contain no more than 255 characters', 'danger')
        }
        if (rehabFormData.dataAccess.length > 255) {
            setAlert('Data Access must contain no more than 255 characters', 'danger')
        }
        if (!validator.isURL(rehabFormData.dataAccess)) {
            setAlert('Invalid website URL', 'danger');
        }
        if (rehabFormData.otherPapers_paperLink.length < rehabFormData.otherPapers_citation.length ||
            rehabFormData.technicalPapers_paperLink.length < rehabFormData.technicalPapers_citation.length) {
            setAlert('Paperlink number must be more than or equal to citation number.', 'danger')
        }
        else {
            rehab_body.selectedPapers = waitSelectedPapers();
            editDataset();
        }
    };


    useEffect(() => {
        const getDatasetByID = async () => {
            try {
                const res = await api.post("dashboard/rehab",
                    {
                        "rehabId": id
                    }, {});
                let otherPapers_link = []
                let otherPapers_cit = []
                for (var i = 0; i < res.data.rehab.selectedPapers.otherPapers.length; i++) {
                    otherPapers_link.push(res.data.rehab.selectedPapers.otherPapers[i].paperLink);
                    if (res.data.rehab.selectedPapers.otherPapers[i].citation !== '') {
                        otherPapers_cit.push(res.data.rehab.selectedPapers.otherPapers[i].citation)
                    }
                }
                let technicalPapers_link = []
                let technicalPapers_cit = []
                for (var i = 0; i < res.data.rehab.selectedPapers.technicalPapers.length; i++) {
                    technicalPapers_link.push(res.data.rehab.selectedPapers.technicalPapers[i].paperLink);
                    if (res.data.rehab.selectedPapers.technicalPapers[i].citation !== '') {
                        technicalPapers_cit.push(res.data.rehab.selectedPapers.technicalPapers[i].citation)
                    }
                }

                setRehabFormData({
                    ...rehabFormData,
                    datasetName: res.data.rehab.basicInformation.datasetName,
                    datasetAcronym: res.data.rehab.basicInformation.datasetAcronym,
                    summary: res.data.rehab.basicInformation.summary,
                    keyTerms: res.data.rehab.basicInformation.keyTerms,
                    studyDesign: res.data.rehab.basicInformation.studyDesign,
                    dataTypes: res.data.rehab.basicInformation.dataTypes,
                    sponsoringAgency: res.data.rehab.basicInformation.sponsoringAgency,
                    healthConditions: res.data.rehab.healthMeasures.healthConditions,
                    measures: res.data.rehab.healthMeasures.measures,
                    topics: res.data.rehab.healthMeasures.topics,
                    samplePopulation: res.data.rehab.sample.samplePopulation,
                    sampleNotes: res.data.rehab.sample.sampleNotes,
                    unitOfObservation: res.data.rehab.sample.unitOfObservation,
                    countries: res.data.rehab.sample.countries,
                    geographicCoverage: res.data.rehab.sample.geographicCoverage,
                    geographicSpecificity: res.data.rehab.sample.geographicSpecificity,
                    specialPopulations: res.data.rehab.sample.specialPopulations,
                    dataCollectionMode: res.data.rehab.dataCollection.dataCollectionMode,
                    fromYearsCollected: res.data.rehab.dataCollection.fromYearsCollected,
                    toYearsCollected: res.data.rehab.dataCollection.toYearsCollected,
                    dataCollectionFrequency: res.data.rehab.dataCollection.dataCollectionFrequency,
                    primaryWebsite: res.data.rehab.dataDetails.primaryWebsite,
                    dataAccess: res.data.rehab.dataDetails.dataAccess,
                    summaryReports: res.data.rehab.dataDetails.summaryReports,
                    dataComponents: res.data.rehab.dataDetails.dataComponents,
                    relatedDatasets: res.data.rehab.dataDetails.relatedDatasets,
                    otherPapers_paperLink: otherPapers_link,
                    otherPapers_citation: otherPapers_cit,
                    technicalPapers_paperLink: technicalPapers_link,
                    technicalPapers_citation: technicalPapers_cit,
                });
                setGetDataset(true);
            } catch (e) {
                console.log(e);
            }
        }
        getDatasetByID()
    }, [id]);


    if (_.isEqual(getDataset, false)) {

        return (
            <div className="background-container pt-5">
                <Loading />
            </div>
        );
    }

    return (
        <section className="container">
            <h1 className="large text-primary">Edit Datasets</h1>
            <h3> Please fill in the required fields with * and use "," to separate a list of words</h3>

            <form className="form" onSubmit={onSubmit_Rehab}>
                <h4 className='top-dis'>Basic Information:</h4>
                <div className="form-group">
                    <h5>Dataset Name: *</h5>
                    <input
                        type="text"
                        placeholder="Dataset Name *"
                        name="datasetName"
                        value={datasetName}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Dataset Acronym:</h5>
                    <input
                        type="text"
                        placeholder="Dataset Acronym"
                        name="datasetAcronym"
                        value={datasetAcronym}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Summary: *</h5>
                    <input
                        type="text"
                        placeholder="Summary *"
                        name="summary"
                        value={summary}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Key Terms: *</h5>
                    <input
                        type="text"
                        placeholder="Key Terms *"
                        name="keyTerms"
                        value={keyTerms}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Study Design: *</h5>
                    <input
                        type="text"
                        placeholder="Study Design *"
                        name="studyDesign"
                        value={studyDesign}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Data Types:</h5>
                    <input
                        type="text"
                        placeholder="Data Types"
                        name="dataTypes"
                        value={dataTypes}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Sponsoring Agency:</h5>
                    <input
                        type="text"
                        placeholder="Sponsoring Agency"
                        name="sponsoringAgency"
                        value={sponsoringAgency}
                        onChange={onChange_Rehab}
                    />
                </div>
                <h4>Health Measures:</h4>
                <div className="form-group">
                    <h5>Health Conditions: *</h5>
                    <input
                        type="text"
                        placeholder="Health Conditions *"
                        name="healthConditions"
                        value={healthConditions}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Measures:</h5>
                    <input
                        type="text"
                        placeholder="Measures"
                        name="measures"
                        value={measures}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Topics:</h5>
                    <input
                        type="text"
                        placeholder="Topics"
                        name="topics"
                        value={topics}
                        onChange={onChange_Rehab}
                    />
                </div>
                <h4>Sample:</h4>
                <div className="form-group">
                    <h5>Sample Population:</h5>
                    <input
                        type="text"
                        placeholder="Sample Population"
                        name="samplePopulation"
                        value={samplePopulation}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Sample Notes:</h5>
                    <input
                        type="text"
                        placeholder="Sample Notes"
                        name="sampleNotes"
                        value={sampleNotes}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Unit Of Observation:</h5>
                    <input
                        type="text"
                        placeholder="Unit Of Observation"
                        name="unitOfObservation"
                        value={unitOfObservation}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Countries:</h5>
                    <input
                        type="text"
                        placeholder="Countries"
                        name="countries"
                        value={countries}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Geographic Coverage:</h5>
                    <input
                        type="text"
                        placeholder="Geographic Coverage"
                        name="geographicCoverage"
                        value={geographicCoverage}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Geographic Specificity:</h5>
                    <input
                        type="text"
                        placeholder="Geographic Specificity"
                        name="geographicSpecificity"
                        value={geographicSpecificity}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Special Populations:</h5>
                    <input
                        type="text"
                        placeholder="Special Populations"
                        name="specialPopulations"
                        value={specialPopulations}
                        onChange={onChange_Rehab}
                    />
                </div>
                <h4>Data Collection:</h4>
                <div className="form-group">
                    <h5>Data Collection Mode:</h5>
                    <input
                        type="text"
                        placeholder="Data Collection Mode"
                        name="dataCollectionMode"
                        value={dataCollectionMode}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>From Years Collected: *</h5>
                    <input
                        type="text"
                        placeholder="From Years Collected *"
                        name="fromYearsCollected"
                        value={fromYearsCollected}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>To Years Collected:</h5>
                    <input
                        type="text"
                        placeholder="To Years Collected"
                        name="toYearsCollected"
                        value={toYearsCollected}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Data Collection Frequency:</h5>
                    <input
                        type="text"
                        placeholder="Data Collection Frequency"
                        name="dataCollectionFrequency"
                        value={dataCollectionFrequency}
                        onChange={onChange_Rehab}
                    />
                </div>
                <h4>Data Details:</h4>
                <div className="form-group">
                    <h5>Primary Website: *</h5>
                    <input
                        type="text"
                        placeholder="Primary Website *"
                        name="primaryWebsite"
                        value={primaryWebsite}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Data Access: *</h5>
                    <input
                        type="text"
                        placeholder="Data Access *"
                        name="dataAccess"
                        value={dataAccess}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Summary Reports:</h5>
                    <input
                        type="text"
                        placeholder="Summary Reports"
                        name="summaryReports"
                        value={summaryReports}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Data Components:</h5>
                    <input
                        type="text"
                        placeholder="Data Components"
                        name="dataComponents"
                        value={dataComponents}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Related Datasets:</h5>
                    <input
                        type="text"
                        placeholder="Related Datasets"
                        name="relatedDatasets"
                        value={relatedDatasets}
                        onChange={onChange_Rehab}
                    />
                </div>
                <h4>Selected Papers:</h4>
                <div className="form-group">
                    <h5>Other Papers' PaperLinks: *</h5>
                    <input
                        type="text"
                        placeholder="Other Papers' PaperLinks *"
                        name="otherPapers_paperLink"
                        value={otherPapers_paperLink}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Other Papers' Citations:</h5>
                    <input
                        type="text"
                        placeholder="Other Papers' Citations"
                        name="otherPapers_citation"
                        value={otherPapers_citation}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Technical Papers' PaperLinks: *</h5>
                    <input
                        type="text"
                        placeholder="Technical Papers' PaperLinks *"
                        name="technicalPapers_paperLink"
                        value={technicalPapers_paperLink}
                        onChange={onChange_Rehab}
                    />
                </div>
                <div className="form-group">
                    <h5>Technical Papers' Citations:</h5>
                    <input
                        type="text"
                        placeholder="Technical Papers' Citations"
                        name="technicalPapers_citation"
                        value={technicalPapers_citation}
                        onChange={onChange_Rehab}
                    />
                </div>
                <input type="submit" className="btn btn-primary" value="Submit" />
            </form>
            <button className="top-dis btn btn-primary" onClick={() => navigate(-1)}>Cancel</button>
        </section>
    );
};

AdminEditDatasets.propTypes = {
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { setAlert })(AdminEditDatasets);

