import React, { useState } from "react";
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const validator = require('validator');

const Panel = ({ login, register, togglePanelShow }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const { email, password } = formData;

    const [showSignupPanel, setShowSignupPanel] = useState(false);

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (e) => {
        e.preventDefault();
        login(email, password);
        togglePanelShow();
    };


    const switchPanel = () => {
        setShowSignupPanel(!showSignupPanel);
    };


    const [formData_signup, setFormDataSignup] = useState({
        name: '',
        email1: '',
        password1: '',
        password2: ''
    });

    const { name, email1, password1, password2 } = formData_signup;

    const register_body = {
        "name": formData_signup.name,
        "email": formData_signup.email1,
        "password": formData_signup.password1
    }

    const onChange_Signup = (e) =>
        setFormDataSignup({ ...formData_signup, [e.target.name]: e.target.value });

    const onSubmit_Signup = async (e) => {
        e.preventDefault();
        if (name.length < 5) {
            window.alert('Name must contain at least 5 characters', 'danger')
        }
        if (name.length > 255) {
            window.alert('Name must contain no more than 255 characters', 'danger')
        }
        if (!validator.isEmail(email1)) {
            window.alert('Invalid email', 'danger');
        }
        if (password1 !== password2) {
            window.alert('Passwords do not match', 'danger');
        }
        if (!password1.match(/\d/) || !password1.match(/[a-zA-Z]/)) {
            window.alert('Password must contain at least one letter and one number', 'danger');
        }
        if (password1.length < 8) {
            window.alert('Password must contain at least 8 characters', 'danger');
        }
        if (password1.length > 2048) {
            window.alert('Password must contain no more than 2048 characters', 'danger');
        } else {
            register(register_body);
            togglePanelShow();
        }
    };


    return (
        <div>
            <div className="panel-overlay">
                <div className="panel-body panel panel-size">
                    <div className="close-panel">
                        <button onClick={() => togglePanelShow()} className="close">
                            <FaTimes color="black" />
                        </button>
                    </div>
                    <div className="panel-centerpart panel-color">
                        <div className="panel-text">
                            <h3 className="h3-style">
                                You're almost done!
                            </h3>
                            <p className="p-style top-dis">
                                Please log in to post your review on ReproYelp
                            </p>
                        </div>

                        {!showSignupPanel ? (
                            <div>
                                <div className="inputbox">
                                    <form className="form" onSubmit={onSubmit}>
                                        <div className="top-dis">
                                            <input
                                                type="email"
                                                placeholder="Email Address"
                                                name="email"
                                                value={email}
                                                onChange={onChange}
                                            />
                                        </div>
                                        <div className="top-dis">
                                            <input
                                                type="password"
                                                placeholder="Password"
                                                name="password"
                                                value={password}
                                                onChange={onChange}
                                                minLength="6"
                                            />
                                        </div>
                                        <p className="text-size top-dis ">
                                            <Link to="/reset_password">Forgot password?</Link>
                                        </p>
                                        <input type="submit" className="top-dis btn btn-primary" value="Login" />
                                    </form>
                                </div>
                                <p className="text-dark top-dis text-size">
                                    Don't have an account? <button onClick={() => switchPanel()}>Sign Up</button>
                                </p>
                            </div>
                        ) : (
                            <div>
                                <div className="inputbox">
                                    <form className="form" onSubmit={onSubmit_Signup}>
                                        <div className="top-dis">
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                name="name"
                                                value={name}
                                                onChange={onChange_Signup}
                                            />
                                        </div>
                                        <div className="top-dis">
                                            <input
                                                type="email"
                                                placeholder="Email Address"
                                                name="email1"
                                                value={email1}
                                                onChange={onChange_Signup}
                                            />
                                        </div>
                                        <div className="top-dis">
                                            <input
                                                type="password"
                                                placeholder="Password"
                                                name="password1"
                                                value={password1}
                                                onChange={onChange_Signup}
                                                minLength="6"
                                            />
                                        </div>
                                        <div className="top-dis">
                                            <input
                                                type="password"
                                                placeholder="Confirm Password"
                                                name="password2"
                                                value={password2}
                                                onChange={onChange_Signup}
                                            />
                                        </div>
                                        <input type="submit" className="top-dis btn btn-primary" value="Register" />
                                    </form>
                                </div>
                                <p className="text-dark my-1 text-size">
                                    Already have an account? <button onClick={() => switchPanel()}>Sign In</button>
                                </p>
                            </div>
                        )}
                        {/* <div className="inputbox">
                            <form className="form" onSubmit={onSubmit}>
                                <div className="top-dis">
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        name="email"
                                        value={email}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="top-dis">
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        value={password}
                                        onChange={onChange}
                                        minLength="6"
                                    />
                                </div>
                                <p className="text-size top-dis ">
                                    <Link to="/reset_password">Forgot password?</Link>
                                </p>
                                <input type="submit" className="top-dis btn btn-primary" value="Login" />
                            </form>
                        </div>
                        <p className="text-dark top-dis text-size">
                            Don't have an account? <Link to="/register">Sign Up</Link>
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Panel;
