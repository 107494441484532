import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import PropTypes from 'prop-types';
import { Loading } from "../common";
import _ from "lodash";
import api from '../../utils/api';

const validator = require('validator');

const ResetPassword = ({ setAlert }) => {
  const [email, setEmail] = useState('');

  const [securityQuestions, setSecurityQuestions] = useState(null);

  const [showForm, setShowForm] = useState(true);

  const [formData, setFormData] = useState({
    question1Answer: '',
    // question2Answer: '',
    newPassword: ''
  });

  const { question1Answer, question2Answer, newPassword } = formData;

  const [isNavigate, setIsNavigate] = useState(false)

  const getSecurityQuestions = async (email) => {
    try {
      const res = await api.post("/auth/security-question", {
        "email": email
      });
      let number = randomNumber();
      //let selectedQuestions = [res.data[number.numberOne], res.data[number.numberTwo]]
      let selectedQuestions = [res.data[number.numberOne]]
      setSecurityQuestions(selectedQuestions);
    } catch (e) {
      console.log(e);
      window.confirm(e.response.data.message);
    }
  }

  const randomNumber = () => {
    let numberOne = 0;
    //let numberTwo = 0;
    numberOne = Math.floor(Math.random() * 3);
    // do {
    //   numberTwo = Math.floor(Math.random() * 3);
    // } while (numberTwo === numberOne);
    return { numberOne: numberOne }; //{ numberOne: numberOne, numberTwo: numberTwo };
  }

  const onChange = (e) =>
    setEmail(e.target.value);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (email === null || email === '') {
      setAlert('Email cannot be null', 'danger')
    }
    if (!validator.isEmail(email)) {
      setAlert('Invalid email', 'danger');
    }
    else {
      getSecurityQuestions(email);
      setShowForm(false);
    }
  };


  const changePassword = async (body) => {
    try {
      const res = await api.post("/auth/forgot-password", body);
      window.confirm("Successfully reset your password!");
      setIsNavigate(true);
    } catch (e) {
      console.log(e);
      window.confirm(e.response.data.message);
    }
  }

  const onResetChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });


  const onResetSubmit = async (e) => {
    e.preventDefault();
    if (formData.Question1Answer === null || formData.Question1Answer === ''
      //||formData.Question2Answer === null || formData.Question2Answer === ''
    ) {
      setAlert('Answer cannot be null', 'danger')
    }
    else {
      let body = {
        "email": email,
        "securityQuestion": securityQuestions[0],
        "securityAnswer": question1Answer,
        "password": newPassword
      }
      changePassword(body);
    }
  };


  if (_.isEqual(showForm, false) && _.isNull(securityQuestions)) {
    return (
      <div className="background-container pt-5">
        <Loading />
      </div>
    );
  }


  if (_.isEqual(isNavigate, true)) {
    return <Navigate to="/login" />;
  }

  return (
    <section className="container">
      <h1 className="large text-primary">Forgot Password</h1>
      <p className="text-dark lead">
        <i className="fas fa-user" /> Please enter your email address and answer the security questions to set a new password.
      </p>
      {!!showForm ? (
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              value={email}
              onChange={onChange}
            />
          </div>
          <input type="submit" className="btn btn-primary" value="Submit" />
          <p className="text-dark my-1 text-size">
            <Link to="/login">Back to Login</Link>
          </p>
        </form>
      ) : (
        !!securityQuestions &&
        <form className="form" onSubmit={onResetSubmit}>
          <h3>{securityQuestions[0]}</h3>
          <div className="form-group">
            <input
              type="text"
              placeholder="Answer Question 1"
              name="question1Answer"
              value={question1Answer}
              onChange={onResetChange}
            />
          </div>
          {/* <h3>{securityQuestions[1]}</h3>
          <div className="form-group">
            <input
              type="text"
              placeholder="Answer Question 2"
              name="question2Answer"
              value={question2Answer}
              onChange={onResetChange}
            />
          </div> */}
          <h3>Enter New Password:</h3>
          <div className="form-group">
            <input
              type="password"
              placeholder="New Password"
              name="newPassword"
              value={newPassword}
              onChange={onResetChange}
            />
          </div>
          <input type="submit" className="btn btn-primary" value="Submit" />
        </form>
      )}
    </section>
  );
};

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { setAlert })(ResetPassword);
