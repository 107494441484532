import React from 'react';
import api from '../../utils/api';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loading, Button, Rating, Panel } from "../../components/common";
import { login } from '../../actions/auth';
import { register } from '../../actions/auth';
import _ from "lodash";

const showCourseDetails = ({ isAuthenticated, login, register }) => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [rating, setRating] = useState(5);
  const [review_text, setReviewText] = useState('');
  const [alert_message, setAlertMessage] = useState('');
  //const [btnDisabled, setBtnDisabled] = useState(true);

  const [showPanel, setShowPanel] = useState(false);


  const createNewCourseReview = async (review_body) => {
    const token = sessionStorage.getItem('tokens')
    try {
      const res = await api.post("/course-review/create", review_body, {
        credentials: 'include',
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      }
      );
      window.confirm("Review Submitted. Please refresh the page to view it.")
    } catch (e) {
      console.log(e);
      if (e.response.data.code === 401 && e.response.data.message.includes('Token expired')) {
        const token = sessionStorage.getItem('tokens')
        const refreshTokens = sessionStorage.getItem('refreshTokens')

        const res = await api.post('/auth/refresh-tokens', { "refreshToken": refreshTokens }, {
          credentials: 'include',
          withCredentials: true,
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        },
        );
        sessionStorage.setItem('tokens', res.data.access.token)
        sessionStorage.setItem('refreshTokens', res.data.refresh.token)

        try {
          const token = sessionStorage.getItem('tokens')

          const res = await api.post("/course-review/create", review_body, {
            credentials: 'include',
            withCredentials: true,
            headers: {
              "Authorization": `Bearer ${token}`,
            },
          }
          );
          window.confirm("Review Submitted. Please refresh the page to view it.")
        } catch (err) {
          console.log("errors:", err)
          window.confirm("Failed to Submit Review.")
        }
      } else {
        window.confirm("Error: " + e.response.data.message);
      }
    }
  }

  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    const getCourseByID = async () => {
      try {
        const res = await api.post("dashboard/course",
          {
            "courseId": id
          }, {});
        setData(res.data);
      } catch (e) {
        console.log(e);
      }
    }
    getCourseByID()
  }, [id]);


  if (_.isEmpty(data)) {

    return (
      <div className="background-container pt-5">
        <Loading />
      </div>
    );
  }
  else {
    console.log(data);
    let average =
      data.courseReviews.results.reduce((accumulator, current) => {
        return accumulator + current.rating;
      }, 0) / data.courseReviews.results.length;
    average = average.toFixed(1);

    const handleTextChange = event => {
      // if (event.target.value === '') {
      //   setBtnDisabled(true);
      //   setAlertMessage(null);
      // } else if (event.target.value !== '' && event.target.value.trim().length <= 2) {
      //   setAlertMessage('Review must be at least 3 characters');
      //   setBtnDisabled(true);
      // } else {
      //   setAlertMessage(null);
      //   setBtnDisabled(false);
      // }
      setReviewText(event.target.value);
    };


    const handleSubmit = event => {
      if (isAuthenticated) {
        event.preventDefault();
        const newReview = {
          "rating": rating,
          "course": id,
          "comments": review_text
        };

        createNewCourseReview(newReview);
      }
      else {
        event.preventDefault();
        togglePanelShow();
      }

    };


    const togglePanelShow = () => {
      setShowPanel(!showPanel);
    };

    return (
      <div className='detail-container'>
        <div className='area-title'>Course Information</div>
        <table className='table'>
          <tbody>
            <tr>
              <td>
                Course Name:
              </td>
              <td>
                <a href={data.course.websiteLink} target="_blank" rel="noopener noreferrer">
                  {data.course.name}
                </a>
              </td>
            </tr>
            <tr>
              <td>
                Level:
              </td>
              <td>
                {capitalizeFirst((data.course.level === 'first_time_user' ? 'First-time user' : data.course.level))}
              </td>
            </tr>
            <tr>
              <td>
                Platform:
              </td>
              <td>
                {data.course.platform}
              </td>
            </tr>
            <tr>
              <td>
                Keywords:
              </td>
              <td>
                {data.course.keywords.join(", ")}
              </td>
            </tr>
            <tr>
              <td>
                Audience:
              </td>
              <td>
                {data.course.audience}
              </td>
            </tr>
            <tr>
              <td>
                Course Length:
              </td>
              <td>
                {(data.course.courseLengthValue < 2 ? (data.course.courseLengthScale === 'hours' ? data.course.courseLengthValue + " hour" : data.course.courseLengthValue + " month") : data.course.courseLengthValue + " " + data.course.courseLengthScale)}
              </td>
            </tr>
            <tr>
              <td>
                researchApplication:
              </td>
              <td>
                {
                  (() => {
                    if (Array.isArray(data.course.researchApplication))
                      return data.course.researchApplication.join(", ")
                    else
                      return data.course.researchApplication
                  })()
                }
              </td>
            </tr>
            <tr>
              <td>
                Fields:
              </td>
              <td>
                {data.course.fields.join(", ")}
              </td>
            </tr>
          </tbody>
        </table>
        <div className='area-title'>Reviews</div>
        <div className="review-card">
          <form onSubmit={handleSubmit}>
            <h3>How would you rate this course?</h3>
            <Rating select={rating => setRating(rating)} />
            <div className="input-group">
              <textarea placeholder="Write a review" onChange={handleTextChange}></textarea>
              {/* <Button disabled={btnDisabled} type="submit" label="Send" /> */}
              <Button type="submit" label="Send" />
            </div>
            <div className="alert_message">{!!alert_message && <>{alert_message}</>}</div>
          </form>
          {!!showPanel && <Panel register={register} login={login} togglePanelShow={togglePanelShow} />}
        </div>
        <div className="feedback-stats">
          <h4>{!!data.courseReviews.results.length ? `${data.courseReviews.results.length}` : "0"}
            {"\u00a0"} Course Reviews</h4>
          <h4>Average Course Rating: {isNaN(average) ? 0 : average}</h4>
        </div>

        {!!data.courseReviews.results.length ? (
          <div className='review-grid'>
            {data.courseReviews.results.map(review => {
              if (review.comments) {
                return (
                  <div className="resource-item">
                    <div className="review-card">
                      <div className="feedback-user">
                        <h4>{review.user.name}</h4>
                        <h4>Rating: {review.rating.toFixed(1)}</h4>
                      </div>
                      <p className='comment-left-margin'>{review.comments}</p>
                    </div>
                  </div>
                )
              }
            })
            }</div>
        ) : (
          <h2 className="text-white">No reviews yet</h2>
        )}
        <br />

      </div>
    )
  }
}

showCourseDetails.propTypes = {
  isAuthenticated: PropTypes.bool,
  login: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});


export default connect(mapStateToProps, { login, register })(showCourseDetails);