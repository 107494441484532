import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import PropTypes from 'prop-types';
import api from '../../utils/api';
import { Dropdown } from '../common';

const validator = require('validator');

const CreateNewResource = ({ setAlert, isAuthenticated }) => {
    // the dropdown menu
    const courseLengthScaleoptions = [
        { label: 'Hour / Hours', value: 'hours' },
        { label: 'Month / Months', value: 'months' },
    ];

    const courseLeveloptions = [
        { label: 'Beginner', value: 'beginner' },
        { label: 'Intermediate', value: 'intermediate' },
        { label: 'Advanced', value: 'advanced' },
        { label: 'First-time user', value: 'first_time_user' },
    ];

    const [courseFormData, setCourseFormData] = useState({
        name: '',
        level: courseLeveloptions[0].value,
        websiteLink: '',
        platform: '',
        keywords: '',
        audience: '',
        courseLengthScale: courseLengthScaleoptions[0].value,
        courseLengthValue: 0,
        researchApplication: '',
        fields: '',
    });

    const [rehabFormData, setRehabFormData] = useState({
        datasetName: '',
        datasetAcronym: '',
        summary: '',
        keyTerms: '',
        studyDesign: '',
        dataTypes: '',
        sponsoringAgency: '',
        healthConditions: '',
        measures: '',
        topics: '',
        samplePopulation: '',
        sampleNotes: '',
        unitOfObservation: '',
        countries: '',
        geographicCoverage: '',
        geographicSpecificity: '',
        specialPopulations: '',
        dataCollectionMode: '',
        fromYearsCollected: 0,
        toYearsCollected: 0,
        dataCollectionFrequency: '',
        primaryWebsite: '',
        dataAccess: '',
        summaryReports: '',
        dataComponents: '',
        relatedDatasets: '',
        otherPapers_paperLink: '',
        otherPapers_citation: '',
        otherPapers: [],
        technicalPapers_paperLink: '',
        technicalPapers_citation: '',
        technicalPapers: [],
    });

    const { name, level, websiteLink, platform, keywords, audience,
        courseLengthScale, courseLengthValue, researchApplication, fields } = courseFormData;

    const { datasetName, datasetAcronym, summary, keyTerms, studyDesign, dataTypes,
        sponsoringAgency, healthConditions, measures, topics, samplePopulation, sampleNotes,
        unitOfObservation, countries, geographicCoverage, geographicSpecificity, specialPopulations,
        dataCollectionMode, fromYearsCollected, toYearsCollected, dataCollectionFrequency, primaryWebsite,
        dataAccess, summaryReports, dataComponents, relatedDatasets, otherPapers_paperLink, otherPapers_citation,
        technicalPapers_paperLink, technicalPapers_citation } = rehabFormData

    let course_body = {
        "name": courseFormData.name,
        "level": courseFormData.level,
        "websiteLink": courseFormData.websiteLink,
        "platform": courseFormData.platform,
        "keywords": [],
        "audience": courseFormData.audience,
        "courseLengthScale": courseFormData.courseLengthScale,
        "courseLengthValue": courseFormData.courseLengthValue,
        "researchApplication": [],
        "fields": []
    }

    let rehab_body = {
        "basicInformation": {
            "datasetName": rehabFormData.datasetName,
            "datasetAcronym": rehabFormData.datasetAcronym,
            "summary": rehabFormData.summary,
            "keyTerms": [],
            "studyDesign": rehabFormData.studyDesign,
            "dataTypes": [],
            "sponsoringAgency": rehabFormData.sponsoringAgency
        },
        "healthMeasures": {
            "healthConditions": [],
            "measures": [],
            "topics": []
        },
        "sample": {
            "samplePopulation": rehabFormData.samplePopulation,
            "sampleNotes": rehabFormData.sampleNotes,
            "unitOfObservation": rehabFormData.unitOfObservation,
            "countries": [],
            "geographicCoverage": rehabFormData.geographicCoverage,
            "geographicSpecificity": rehabFormData.geographicSpecificity,
            "specialPopulations": rehabFormData.specialPopulations
        },
        "dataCollection": {
            "dataCollectionMode": rehabFormData.dataCollectionMode,
            "fromYearsCollected": rehabFormData.fromYearsCollected,
            "toYearsCollected": rehabFormData.toYearsCollected,
            "dataCollectionFrequency": rehabFormData.dataCollectionFrequency
        },
        "dataDetails": {
            "primaryWebsite": rehabFormData.primaryWebsite,
            "dataAccess": rehabFormData.dataAccess,
            "dataAccessRequirements": "Admin rights",
            "summaryReports": rehabFormData.summaryReports,
            "dataComponents": rehabFormData.dataComponents,
            "relatedDatasets": []
        },
        "selectedPapers": {}
    }

    const [showCourseForm, setShowCourseForm] = useState(true);

    const switchCourseForm = () => {
        setShowCourseForm(true);
    };
    const switchRehabForm = () => {
        setShowCourseForm(false);
    };

    const createNewCourse = async (course_body) => {
        const token = sessionStorage.getItem('tokens')
        try {
            const res = await api.post("/course/create", course_body, {
                credentials: 'include',
                withCredentials: true,
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            }
            );
            window.confirm("New Course Created.")
        } catch (e) {
            console.log(e);
            if (e.response.data.code === 401 && e.response.data.message.includes('Token expired')) {
                const token = sessionStorage.getItem('tokens')
                const refreshTokens = sessionStorage.getItem('refreshTokens')

                const res = await api.post('/auth/refresh-tokens', { "refreshToken": refreshTokens }, {
                    credentials: 'include',
                    withCredentials: true,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                },
                );
                sessionStorage.setItem('tokens', res.data.access.token)
                sessionStorage.setItem('refreshTokens', res.data.refresh.token)

                try {
                    const token = sessionStorage.getItem('tokens')

                    const res = await api.post("/course/create", course_body, {
                        credentials: 'include',
                        withCredentials: true,
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        },
                    }
                    );
                    window.confirm("New Course Created.")
                } catch (err) {
                    console.log("errors:", err)
                    window.confirm("Failed to Create the Course.")
                }
            } else {
                window.confirm("Error: " + e.response.data.message);
            }
        }
    }

    const createNewRehab = async (rehab_body) => {
        waitSelectedPapers();
        const token = sessionStorage.getItem('tokens')
        try {
            const res = await api.post("/rehab/create", rehab_body, {
                credentials: 'include',
                withCredentials: true,
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            }
            );
            window.confirm("New Dataset Created.")
        } catch (e) {
            console.log(e);
            if (e.response.data.code === 401 && e.response.data.message.includes('Token expired')) {
                const token = sessionStorage.getItem('tokens')
                const refreshTokens = sessionStorage.getItem('refreshTokens')

                const res = await api.post('/auth/refresh-tokens', { "refreshToken": refreshTokens }, {
                    credentials: 'include',
                    withCredentials: true,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                },
                );
                sessionStorage.setItem('tokens', res.data.access.token)
                sessionStorage.setItem('refreshTokens', res.data.refresh.token)

                try {
                    const token = sessionStorage.getItem('tokens')

                    const res = await api.post("/rehab/create", rehab_body, {
                        credentials: 'include',
                        withCredentials: true,
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        },
                    }
                    );
                    window.confirm("New Dataset Created.")
                } catch (err) {
                    console.log("errors:", err)
                    window.confirm("Failed to Create the Dataset.")
                }
            }
            else {
                window.confirm("Error: " + e.response.data.message);
            }
        }
    }

    const onChange_Course = (e) => {
        if (e.target.name === 'level') {
            let value = e.target.value;
            if (value === 'First-time user') {
                setCourseFormData({ ...courseFormData, [e.target.name]: 'first_time_user' });
            }
        }
        setCourseFormData({ ...courseFormData, [e.target.name]: e.target.value });
    }


    const onSubmit_Course = (e) => {
        e.preventDefault();
        if (courseFormData.name === null || courseFormData.name === '') {
            setAlert('Name cannot be null', 'danger')
        }
        if (courseFormData.websiteLink === null || courseFormData.websiteLink === '') {
            setAlert('Website Link cannot be null', 'danger')
        }
        if (courseFormData.keywords === null || courseFormData.keywords === '') {
            setAlert('Keywords cannot be null', 'danger')
        }
        if (courseFormData.name.length > 255) {
            setAlert('Name must contain no more than 255 characters', 'danger')
        }
        if (courseFormData.platform.length > 255) {
            setAlert('Platform must contain no more than 255 characters', 'danger')
        }
        if (!validator.isURL(courseFormData.websiteLink)) {
            setAlert('Invalid website URL', 'danger');
        }
        else {
            course_body.keywords = courseFormData.keywords.split(";").map(function (item) {
                return item.trim();
            });
            course_body.researchApplication = courseFormData.researchApplication.split(";").map(function (item) {
                return item.trim();
            });
            course_body.fields = courseFormData.fields.split(";").map(function (item) {
                return item.trim();
            });
            createNewCourse(course_body);
        }
    };


    const waitSelectedPapers = () => {
        let otherPapersResults = [];
        let technicalPapersResults = [];
        let op_paperLink = rehabFormData.otherPapers_paperLink.split(";").map(function (item) {
            return item.trim();
        });
        let op_citation = rehabFormData.otherPapers_citation.split(";").map(function (item) {
            return item.trim();
        });
        let tp_paperLink = rehabFormData.technicalPapers_paperLink.split(";").map(function (item) {
            return item.trim();
        });
        let tp_citation = rehabFormData.technicalPapers_citation.split(";").map(function (item) {
            return item.trim();
        });
        for (var i = 0; i < op_citation.length; i++) {
            otherPapersResults.push(
                {
                    "paperLink": op_paperLink[i],
                    "citation": op_citation[i]
                }
            );
        }
        for (var i = op_citation.length; i < op_paperLink.length; i++) {
            otherPapersResults.push(
                {
                    "paperLink": op_paperLink[i],
                    "citation": ""
                }
            );
        }

        for (var i = 0; i < tp_citation.length; i++) {
            technicalPapersResults.push(
                {
                    "paperLink": tp_paperLink[i],
                    "citation": tp_citation[i]
                }
            );
        }
        for (var i = tp_citation.length; i < tp_paperLink.length; i++) {
            technicalPapersResults.push(
                {
                    "paperLink": tp_paperLink[i],
                    "citation": ""
                }
            );
        }

        return {
            "otherPapers": otherPapersResults,
            "technicalPapers": technicalPapersResults
        }
    }

    const onChange_Rehab = (e) => {
        setRehabFormData({ ...rehabFormData, [e.target.name]: e.target.value });
    }


    const onSubmit_Rehab = (e) => {
        e.preventDefault();
        if (rehabFormData.datasetName === null || rehabFormData.datasetName === '') {
            setAlert('Dataset Name cannot be null', 'danger')
        }
        if (rehabFormData.summary === null || rehabFormData.summary === '') {
            setAlert('Summary cannot be null', 'danger')
        }
        if (rehabFormData.keyTerms === null || rehabFormData.keyTerms === '') {
            setAlert('Key Terms cannot be null', 'danger')
        }
        if (rehabFormData.studyDesign === null || rehabFormData.studyDesign === '') {
            setAlert('Study Design cannot be null', 'danger')
        }
        if (rehabFormData.healthConditions === null || rehabFormData.healthConditions === '') {
            setAlert('Health Conditions cannot be null', 'danger')
        }
        if (rehabFormData.primaryWebsite === null || rehabFormData.primaryWebsite === '') {
            setAlert('Primary Website cannot be null', 'danger')
        }
        if (rehabFormData.dataAccess === null || rehabFormData.dataAccess === '') {
            setAlert('Data Access cannot be null', 'danger')
        }
        if (rehabFormData.datasetName.length > 255) {
            setAlert('Dataset Name must contain no more than 255 characters', 'danger')
        }
        if (rehabFormData.datasetAcronym.length > 255) {
            setAlert('Dataset Acronym must contain no more than 255 characters', 'danger')
        }
        if (rehabFormData.unitOfObservation.length > 255) {
            setAlert('Unit Of Observation must contain no more than 255 characters', 'danger')
        }
        if (rehabFormData.dataCollectionMode.length > 255) {
            setAlert('Data Collection Mode must contain no more than 255 characters', 'danger')
        }
        if (rehabFormData.dataCollectionFrequency.length > 2048) {
            setAlert('Data Collection Frequency must contain no more than 2048 characters', 'danger');
        }
        if (rehabFormData.primaryWebsite.length > 255) {
            setAlert('Primary Website must contain no more than 255 characters', 'danger')
        }
        if (rehabFormData.dataAccess.length > 255) {
            setAlert('Data Access must contain no more than 255 characters', 'danger')
        }
        if (!validator.isURL(rehabFormData.dataAccess)) {
            setAlert('Invalid website URL', 'danger');
        }
        if (rehabFormData.otherPapers_paperLink.length < rehabFormData.otherPapers_citation.length ||
            rehabFormData.technicalPapers_paperLink.length < rehabFormData.technicalPapers_citation.length) {
            setAlert('Paperlink number must be more than or equal to citation number.', 'danger')
        }
        else {
            rehab_body.basicInformation.keyTerms = rehabFormData.keyTerms.split(";").map(function (item) {
                return item.trim();
            });
            rehab_body.basicInformation.dataTypes = rehabFormData.dataTypes.split(";").map(function (item) {
                return item.trim();
            });
            rehab_body.healthMeasures.healthConditions = rehabFormData.healthConditions.split(";").map(function (item) {
                return item.trim();
            });
            rehab_body.healthMeasures.measures = rehabFormData.measures.split(";").map(function (item) {
                return item.trim();
            });
            rehab_body.healthMeasures.topics = rehabFormData.topics.split(";").map(function (item) {
                return item.trim();
            });
            rehab_body.sample.countries = rehabFormData.countries.split(";").map(function (item) {
                return item.trim();
            });
            rehab_body.dataDetails.relatedDatasets = rehabFormData.relatedDatasets.split(";").map(function (item) {
                return item.trim();
            });
            rehab_body.selectedPapers = waitSelectedPapers();
            createNewRehab(rehab_body);
        }
    };



    // the dropdown menu
    const handleCourseLengthScaleChange = (event) => {
        setCourseFormData({
            ...courseFormData, courseLengthScale: event.target.value
        });
    };

    const handleCourseLevelChange = (event) => {
        setCourseFormData({
            ...courseFormData, level: event.target.value
        });
    };

    return (
        <section className="container">
            <h1 className="large text-primary">Add New Resource</h1>
            <input type="submit" className="btn btn-primary" value="Course" onClick={() => switchCourseForm()} />
            <input type="submit" className="left-margin btn btn-primary" value="Dataset" onClick={() => switchRehabForm()} />
            <p className="text-dark lead">
                <i className="fas fa-user" /> Upload resources that you have
            </p>
            <h3> Please fill in the required fields with * and use ";" to separate a list of words</h3>


            {showCourseForm ? (
                <form className="form" onSubmit={onSubmit_Course}>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Name *"
                            name="name"
                            value={name}
                            onChange={onChange_Course}
                        />
                    </div>
                    <div className="form-group">
                        <label>Course Level:</label>
                        <Dropdown options={courseLeveloptions} value={level} onChange={handleCourseLevelChange} />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Website Link *"
                            name="websiteLink"
                            value={websiteLink}
                            onChange={onChange_Course}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Platform"
                            name="platform"
                            value={platform}
                            onChange={onChange_Course}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Keywords *"
                            name="keywords"
                            value={keywords}
                            onChange={onChange_Course}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Audience"
                            name="audience"
                            value={audience}
                            onChange={onChange_Course}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Course Length"
                            name="courseLengthValue"
                            //value={courseLengthValue}
                            onChange={onChange_Course}
                        />
                        <Dropdown options={courseLengthScaleoptions} value={courseLengthScale} onChange={handleCourseLengthScaleChange} />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Research Application"
                            name="researchApplication"
                            value={researchApplication}
                            onChange={onChange_Course}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Fields"
                            name="fields"
                            value={fields}
                            onChange={onChange_Course}
                        />
                    </div>
                    <input type="submit" className="btn btn-primary" value="Submit" />
                </form>
            ) : (
                <form className="form" onSubmit={onSubmit_Rehab}>
                    <h4 className='top-dis'>Basic Information:</h4>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Dataset Name *"
                            name="datasetName"
                            value={datasetName}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Dataset Acronym"
                            name="datasetAcronym"
                            value={datasetAcronym}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Summary *"
                            name="summary"
                            value={summary}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Key Terms *"
                            name="keyTerms"
                            value={keyTerms}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Study Design *"
                            name="studyDesign"
                            value={studyDesign}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Data Types"
                            name="dataTypes"
                            value={dataTypes}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Sponsoring Agency"
                            name="sponsoringAgency"
                            value={sponsoringAgency}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <h4>Health Measures:</h4>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Health Conditions *"
                            name="healthConditions"
                            value={healthConditions}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Measures"
                            name="measures"
                            value={measures}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Topics"
                            name="topics"
                            value={topics}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <h4>Sample:</h4>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Sample Population"
                            name="samplePopulation"
                            value={samplePopulation}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Sample Notes"
                            name="sampleNotes"
                            value={sampleNotes}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Unit Of Observation"
                            name="unitOfObservation"
                            value={unitOfObservation}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Countries"
                            name="countries"
                            value={countries}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Geographic Coverage"
                            name="geographicCoverage"
                            value={geographicCoverage}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Geographic Specificity"
                            name="geographicSpecificity"
                            value={geographicSpecificity}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Special Populations"
                            name="specialPopulations"
                            value={specialPopulations}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <h4>Data Collection:</h4>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Data Collection Mode"
                            name="dataCollectionMode"
                            value={dataCollectionMode}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Start Year Collected"
                            name="fromYearsCollected"
                            // value={fromYearsCollected}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="End Year Collected"
                            name="toYearsCollected"
                            // value={toYearsCollected}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Data Collection Frequency"
                            name="dataCollectionFrequency"
                            value={dataCollectionFrequency}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <h4>Data Details:</h4>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Primary Website *"
                            name="primaryWebsite"
                            value={primaryWebsite}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Data Access Link *"
                            name="dataAccess"
                            value={dataAccess}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Summary Reports"
                            name="summaryReports"
                            value={summaryReports}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Data Components"
                            name="dataComponents"
                            value={dataComponents}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Related Datasets"
                            name="relatedDatasets"
                            value={relatedDatasets}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <h4>Selected Papers:</h4>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="List Other Paper Links"
                            name="otherPapers_paperLink"
                            value={otherPapers_paperLink}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="List Other Paper Names/Citations"
                            name="otherPapers_citation"
                            value={otherPapers_citation}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="List Technical Paper Links"
                            name="technicalPapers_paperLink"
                            value={technicalPapers_paperLink}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="List Technical Paper Names/Citations"
                            name="technicalPapers_citation"
                            value={technicalPapers_citation}
                            onChange={onChange_Rehab}
                        />
                    </div>
                    <input type="submit" className="btn btn-primary" value="Submit" />
                </form>
            )
            }
        </section>
    );
};

CreateNewResource.propTypes = {
    setAlert: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert })(CreateNewResource);

