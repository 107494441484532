//import Axios from "axios";
import api from '../utils/api';
import {
  GET_SEARCH_COURSES_ERROR,
  GET_SEARCH_REHABS_ERROR,
} from "./types";

export const getCourses = (searchText, limit, page) => async (dispatch) => {
  let body = { "name": searchText };
  if (searchText === "" || searchText === null) {
    body = {}
  }
  try {
    const result = await api.post(`/course?limit=${limit}&page=${page}`, body);
    return result;
  } catch (err) {
    window.confirm("Error: " + err.response.data.message);
    dispatch({ type: GET_SEARCH_COURSES_ERROR });
  }
};


export const getRehabs = (searchText, limit, page) => async (dispatch) => {
  let body = {
    "basicInformation": {
      "datasetName": searchText
    }
  };
  if (searchText === "" || searchText === null) {
    body = {}
  }
  try {
    const result = await api.post(`/rehab?limit=${limit}&page=${page}`, body);
    return result;
  } catch (err) {
    window.confirm("Error: " + err.response.data.message);
    dispatch({ type: GET_SEARCH_REHABS_ERROR });
  }
};