import React from "react";
import { Link } from 'react-router-dom';

const RehabCard = ({ rehab_item }) => {
  return (
    <div className="rehab_card">
      <h4> {rehab_item.basicInformation.datasetName} </h4>
      <div className="tag">
        <i>Dataset</i>
      </div>
      <div className="keyword">
        <i>Keywords:</i> {rehab_item.basicInformation.keyTerms.join(", ")}
      </div>

      <Link to={`/rehab/${rehab_item.id}`} className="trailer-btn">
        view details
      </Link>
    </div>
  );
};
export default RehabCard;

