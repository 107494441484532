import axios from 'axios';
import store from '../store';

// Create an instance of axios
axios.defaults.withCredentials = true;
const api = axios.create({
  baseURL: '/api',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  }
});
/*
  NOTE: intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated.
*/

api.interceptors.response.use(
  (res) => res,
  (err) => {
    // if (err.response.status === 401) {
    //   store.dispatch({ type: LOGOUT });
    // }
    return Promise.reject(err);
  }
);

export default api;
