import api from '../utils/api';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from './types';
import setAuthToken from '../utils/setAuthToken';

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

// Load User
export const loadUser = () => async (dispatch) => {
  if (sessionStorage.tokens) {
    setAuthToken(sessionStorage.tokens)
  }
  try {
    const token = sessionStorage.getItem('tokens');
    const res = await api.post('/users', {}, {
      withCredentials: true,
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

// Register User
export const register = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/auth/register', formData);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    window.confirm("Error: " + err.response.data.message);

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REGISTER_FAIL
    });
  }
};

// Login User
export const login = (email, password) => async (dispatch) => {
  const body = { email, password };

  try {
    const res = await api.post('/auth/login', body);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    window.confirm("Error: " + err.response.data.message);

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// Logout
export const logout = () => async (dispatch) => {
  if (sessionStorage.tokens) {
    setAuthToken(sessionStorage.tokens)
  }
  try {
    const token = sessionStorage.getItem('tokens')

    const res = await api.post('/auth/logout', {}, {
      credentials: 'include',
      withCredentials: true,
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    },
    );
    dispatch({ type: LOGOUT });
  } catch (err) {
    console.log("errors:", err)
    if (err.response.data.code === 401 && err.response.data.message.includes('Token expired')) {
      const token = sessionStorage.getItem('tokens')
      const refreshTokens = sessionStorage.getItem('refreshTokens')

      const res = await api.post('/auth/refresh-tokens', { "refreshToken": refreshTokens }, {
        credentials: 'include',
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      },
      );
      sessionStorage.setItem('tokens', res.data.access.token)
      sessionStorage.setItem('refreshTokens', res.data.refresh.token)

      try {
        const token = sessionStorage.getItem('tokens')

        const res = await api.post('/auth/logout', {}, {
          credentials: 'include',
          withCredentials: true,
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        },
        );
        dispatch({ type: LOGOUT });
      } catch (err) {
        console.log("errors:", err)
        window.confirm("Error: " + err.response.data.message);
        dispatch({
          type: AUTH_ERROR
        });
      }
    }
  }
};

// Refresh Tokens
export const refreshTokens = () => async (dispatch) => {
  if (sessionStorage.tokens) {
    setAuthToken(sessionStorage.tokens)
  }
  try {
    const token = sessionStorage.getItem('tokens')
    const refreshTokens = sessionStorage.getItem('refreshTokens')

    const res = await api.post('/auth/refresh-tokens', { "refreshToken": refreshTokens }, {
      credentials: 'include',
      withCredentials: true,
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    },
    );
  } catch (err) {
    const errors = err.response.data.errors;
    window.confirm("Error: " + err.response.data.message);

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGOUT
    });
  }
};





