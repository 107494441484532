import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types';
import { Dropdown } from '../common';

const validator = require('validator');

const Register = ({ setAlert, register, isAuthenticated }) => {

  //dropdown menu for security questions
  const securityQuestionOptions = [
    { label: 'What city were you born in?', value: 'What city were you born in?' },
    { label: 'What is your oldest sibling\'s middle name?', value: 'What is your oldest sibling\'s middle name?' },
    { label: 'What was the first concert you attended?', value: 'What was the first concert you attended?' },
    { label: 'What was the model of your first car?', value: 'What was the model of your first car?' },
    { label: 'In what city or town did your parents meet?', value: 'In what city or town did your parents meet?' },
    { label: 'What\'s the name of your first school?', value: 'What\'s the name of your first school?' },
    { label: 'What\'s the name of your first pet?', value: 'What\'s the name of your first pet?' },
    { label: 'What\'s the name of your favourite childhood toy?', value: 'What\'s the name of your favourite childhood toy?' },
    { label: 'Where did you travel on your honeymoon?', value: 'Where did you travel on your honeymoon?' },
  ];

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
    securityQuestion1: securityQuestionOptions[0].value,
    securityAnswer1: '',
    securityQuestion2: securityQuestionOptions[1].value,
    securityAnswer2: '',
    securityQuestion3: securityQuestionOptions[2].value,
    securityAnswer3: ''
  });

  const { name, email, password, password2, securityQuestion1, securityAnswer1, securityQuestion2, securityAnswer2,
    securityQuestion3, securityAnswer3 } = formData;


  //dropdown menu
  const getAvailableSecurityQuestions = (question1, question2) => {
    let availableOptionsLeft = securityQuestionOptions;
    return availableOptionsLeft.filter(questionOption => (
      questionOption.value !== question1 && questionOption.value !== question2
    ));
  }

  const securityQuestion1Change = (event) => {
    setFormData({
      ...formData, securityQuestion1: event.target.value
    });
  };
  const securityQuestion2Change = (event) => {
    setFormData({
      ...formData, securityQuestion2: event.target.value
    });
  };
  const securityQuestion3Change = (event) => {
    setFormData({
      ...formData, securityQuestion3: event.target.value
    });
  };


  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (name.length < 2) {
      setAlert('Name must contain at least 2 characters', 'danger')
    }
    if (name.length > 255) {
      setAlert('Name must contain no more than 255 characters', 'danger')
    }
    if (!validator.isEmail(email)) {
      setAlert('Invalid email', 'danger');
    }
    if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    }
    if (!password.match(/\d/) || !password.match(/[a-zA-Z]/)) {
      setAlert('Password must contain at least one letter and one number', 'danger');
    }
    if (password.length < 8) {
      setAlert('Password must contain at least 8 characters', 'danger');
    }
    if (password.length > 2048) {
      setAlert('Password must contain no more than 2048 characters', 'danger');
    }
    if (securityAnswer1 === null || securityAnswer1 === "" || securityAnswer2 === null || securityAnswer2 === "" ||
      securityAnswer3 === null || securityAnswer3 === "") {
      setAlert('Security question\'s answer cannot be null', 'danger');
    }
    else {
      register({
        name, email, password, securityQuestion1, securityAnswer1,
        securityQuestion2, securityAnswer2, securityQuestion3, securityAnswer3
      });
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <section className="container">
      <h1 className="large text-primary">Sign Up</h1>
      <p className="text-dark lead">
        <i className="fas fa-user" /> Create Your Account
      </p>
      <form className="form" onSubmit={onSubmit}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={name}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            placeholder="Email Address"
            name="email"
            value={email}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Confirm Password"
            name="password2"
            value={password2}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <label>Security Question1:</label>
          <Dropdown options={getAvailableSecurityQuestions(formData.securityQuestion2, formData.securityQuestion3)}
            value={formData.securityQuestion1}
            onChange={securityQuestion1Change} />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Answer security question 1"
            name="securityAnswer1"
            value={securityAnswer1}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <label>Security Question2:</label>
          <Dropdown options={getAvailableSecurityQuestions(formData.securityQuestion1, formData.securityQuestion3)}
            value={formData.securityQuestion2}
            onChange={securityQuestion2Change} />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Answer security question 2"
            name="securityAnswer2"
            value={securityAnswer2}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <label>Security Question3:</label>
          <Dropdown options={getAvailableSecurityQuestions(formData.securityQuestion1, formData.securityQuestion2)}
            value={formData.securityQuestion3}
            onChange={securityQuestion3Change} />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Answer security question 3"
            name="securityAnswer3"
            value={securityAnswer3}
            onChange={onChange}
          />
        </div>
        <input type="submit" className="btn btn-primary" value="Register" />
      </form>
      <p className="text-dark my-1 text-size">
        Already have an account? <Link to="/login">Sign In</Link>
      </p>
    </section>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, register })(Register);
