import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getCourses } from "../../actions/course_rehab";
import api from '../../utils/api';
import _ from "lodash";
import { Pagination } from "..";
import { Loading } from "../../components/common";



const AdminExistingCourses = ({ getCourses }) => {

    const sorting = {
        mostRelevance: "most_relevance",
        AToZ: "a_z_alphabetically",
        ZToA: "z_a_alphabetically",
        increasingCourseLength: "increasing_course_length",
        decreasingCourseLength: "decreasing_course_length",
    }

    const filteringInitialState = {
        sorting: "",
        paging: 1
    }

    const [searchResults, setSearchResults] = useState(null);

    const [displayResults, setDisplayResults] = useState(null);  //searchresult->resourresult to show

    const [selectedSortingValue, setSelectedSortingValue] = useState(filteringInitialState.sorting);
    const [selectedPage, setSelectedPage] = useState(filteringInitialState.paging);

    const [formData, setFormData] = useState({
        pageSize: 30,
        searchText: "",
        itemsCount: 0,
        totalPages: 0
    });


    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const deleteCourse = async (resource_item) => {
        if (window.confirm('Delete the course?')) {
            const token = sessionStorage.getItem('tokens')
            try {
                const res = await api.delete("/course", {
                    credentials: 'include',
                    withCredentials: true,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                    data: {
                        "courseId": resource_item.id
                    }
                }
                );
                window.confirm("Course Deleted. Please refresh the page to view.")
            } catch (e) {
                console.log(e);
                if (e.response.data.code === 401 && e.response.data.message.includes('Token expired')) {
                    const token = sessionStorage.getItem('tokens')
                    const refreshTokens = sessionStorage.getItem('refreshTokens')

                    const res = await api.post('/auth/refresh-tokens', { "refreshToken": refreshTokens }, {
                        credentials: 'include',
                        withCredentials: true,
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        },
                    },
                    );
                    sessionStorage.setItem('tokens', res.data.access.token)
                    sessionStorage.setItem('refreshTokens', res.data.refresh.token)

                    try {
                        const token = sessionStorage.getItem('tokens')
                        const res = await api.delete("/course", {
                            credentials: 'include',
                            withCredentials: true,
                            headers: {
                                "Authorization": `Bearer ${token}`,
                            },
                            data: {
                                "courseId": resource_item.id
                            }
                        }
                        );
                        window.confirm("Course Deleted. Please refresh the page to view.")
                    } catch (err) {
                        console.log("errors:", err)
                        window.confirm("Failed to Delete Course.")
                    }
                } else {
                    window.confirm("Error: " + e.response.data.message);
                }
            }
        }
    }



    useEffect(() => {
        const waitResources = async () => {
            let res_course = await getCourses("", 30, selectedPage);
            combineAllResults(res_course.data);
        }
        waitResources();
    }, []);

    const onChange = e => {
        setFormData({ ...formData, searchText: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const waitFilterResources = async () => {
            let res_course = await getCourses(formData.searchText, 30, selectedPage);
            combineAllResults(res_course.data);
        }
        waitFilterResources();
        if (formData.searchText !== "") {
            setSelectedSortingValue(sorting.mostRelevance);
        }
        else {
            setSelectedSortingValue(filteringInitialState.sorting);
        }
        setSelectedPage(filteringInitialState.paging);
    };

    const onPageChange = (page) => {
        setSelectedPage(page);
        setSelectedSortingValue(filteringInitialState.sorting);
        const waitFilterResources = async () => {
            let res_course;
            res_course = await getCourses(formData.searchText, 30, page);
            combineAllResults(res_course.data);
        }
        waitFilterResources();

    };


    const combineAllResults = (courses) => {
        setFormData({ ...formData, totalPages: (courses.totalPages ? courses.totalPages : 1) });
        setDisplayResults(courses.results);
        setSearchResults(courses.results);
        setFormData({ ...formData, itemsCount: (courses.totalResults ? courses.totalResults : 0) });


    }

    const getName = (resourceItem) => {
        if (resourceItem.category === "course") {
            return resourceItem.name;
        }
        else {
            return "";
        }
    }
    const filterResults = (param) => {
        if (param.sorting) {
            setSelectedSortingValue(param.sorting);
        }
        if (param.paging) {
            setSelectedPage(param.paging);
        }
        let sortParam = param.sorting || selectedSortingValue;
        let pageParam = param.paging || selectedPage;

        // 1. do filtering based on searchResult
        // 2. do sorting based on result 1
        // 3. filtering results on specific page on paging
        // 4. set filtering results to displayResults
        let newResult = [...searchResults]
        if (sortParam === sorting.increasingCourseLength) {
            newResult.sort((a, b) => a.courseLengthTotalHours - b.courseLengthTotalHours)
        }
        else if (sortParam === sorting.decreasingCourseLength) {
            newResult.sort((a, b) => b.courseLengthTotalHours - a.courseLengthTotalHours)
        }
        if (sortParam === sorting.mostRelevance) {
            if (formData.searchText !== "") {
                newResult.sort((a, b) => b.score - a.score)
            }
        }
        else if (sortParam === sorting.AToZ) {
            newResult.sort((a, b) => {
                let name1 = getName(a);
                let name2 = getName(b);
                return name1.localeCompare(name2);
            })
        }
        else if (sortParam === sorting.ZToA) {
            newResult.sort((a, b) => {
                let name1 = getName(a);
                let name2 = getName(b);
                return name2.localeCompare(name1);
            })
        }
        setDisplayResults(newResult);
    }

    if (_.isNull(displayResults)) {

        return (
            <div className="background-container pt-5">
                <Loading />
            </div>
        );
    }

    return (
        <div className="background-container">
            <div className="mx-5 py-5">
                <div className="row">
                    <div>
                        <h4 className="text-muted text-left p-1">Sorting</h4>
                        <div>
                            <a className={"sorting-link " + (selectedSortingValue === 'most_relevance' ? 'active-sorting-link' : '')} onClick={() => filterResults({ sorting: "most_relevance" })}>Most Relevance</a>
                        </div>
                        <div>
                            <a className={"sorting-link " + (selectedSortingValue === 'a_z_alphabetically' ? 'active-sorting-link' : '')} onClick={() => filterResults({ sorting: "a_z_alphabetically" })}>Alphabetically A-Z</a>
                        </div>
                        <div>
                            <a className={"sorting-link " + (selectedSortingValue === 'z_a_alphabetically' ? 'active-sorting-link' : '')} onClick={() => filterResults({ sorting: "z_a_alphabetically" })}>Alphabetically Z-A</a>
                        </div>
                        <div>
                            <div>
                                <a className={"sorting-link " + (selectedSortingValue === 'increasing_course_length' ? 'active-sorting-link' : '')} onClick={() => filterResults({ sorting: "increasing_course_length" })}>Least Hours</a>
                            </div>
                            <div>
                                <a className={"sorting-link " + (selectedSortingValue === 'decreasing_course_length' ? 'active-sorting-link' : '')} onClick={() => filterResults({ sorting: "decreasing_course_length" })}>Most Hours</a>
                            </div>
                        </div>
                    </div>

                    <div className="search-area">
                        <form onSubmit={onSubmit} className="search-form-style">
                            <input
                                className="search-style"
                                type="text"
                                placeholder="Search..."
                                onChange={onChange}
                            />
                            <input className="submit-search1 btn-primary" type="submit" value="Search" />
                        </form>

                        <p className="text-dark text-left text-muted ">
                            {formData.itemsCount !== 0 ? `${formData.itemsCount}` : "0"}
                            {"\u00a0"} resources found.
                        </p>

                        {!!displayResults ? (
                            <div className='resource-grid'>
                                {displayResults.map(resource => {
                                    return (
                                        <div className="resource-item">
                                            <div className="course_card">
                                                <h4> {resource.name} </h4>
                                                <div className="tag">
                                                    <i>Course ·</i> {(resource.courseLengthValue <= 1 ? (resource.courseLengthScale === 'hours' ? resource.courseLengthValue + " hour" : resource.courseLengthValue + " month") : resource.courseLengthValue + " " + resource.courseLengthScale)}
                                                </div>
                                                <div className="level">
                                                    <i>Level:</i> {capitalizeFirst((resource.level === 'first_time_user' ? 'First-time user' : resource.level))}
                                                </div>
                                                <div className="keyword">
                                                    <i>Keywords:</i> {resource.keywords.join(", ")}
                                                </div>

                                                <Link to={`/course/${resource.id}`} className="trailer-btn">
                                                    View Details
                                                </Link>
                                                <div className='two-buttons-layout'>
                                                    <Link to={`/admin_edit_course/${resource.id}`} className="edit-button">
                                                        Edit
                                                    </Link>
                                                    <button onClick={() => deleteCourse(resource)}>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }</div>
                        ) : (
                            <h1 className="text-white">No Resources</h1>
                        )}
                        <br />

                        <Pagination
                            itemsCount={formData.itemsCount}
                            pageSize={formData.pageSize}
                            onPageChange={onPageChange}
                            currentPage={selectedPage}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
};

AdminExistingCourses.propTypes = {
    getCourses: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { getCourses })(AdminExistingCourses);
