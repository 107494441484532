import React from 'react';

const Dropdown = ({ options, value, onChange }) => {
  return (
    <label>
      <select className="dropdown-style" onChange={onChange} value={value}>
        {options.map((option) => (
          <option key={option.value.id} value={option.value}>{option.label}</option>
        ))}
      </select>
    </label>
  );
};

export default Dropdown;