import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import ResetPassword from './components/auth/ResetPassword';
import Alert from './components/layout/Alert';
import Dashboard from './components/dashboard/Dashboard';
import NotFound from './components/layout/NotFound';
import PrivateRoute from './components/routing/PrivateRoute';
import AdminRoute from './components/routing/AdminRoute';
import ShowCourseDetails from './components/ResourceDetailedPage/ShowCourseDetails';
import ShowRehabDetails from './components/ResourceDetailedPage/ShowRehabDetails';
import CreateNewResource from './components/CreateNewResource/CreateNewResource';
import EditReview from './components/EditReviewPage/EditReview';
import AdminDashBoard from './components/admin/AdminDashBoard';
import AdminExistingCourses from './components/admin/AdminExistingCourses';
import AdminEditCourses from './components/admin/AdminEditCourses';
import AdminExistingDatasets from './components/admin/AdminExistingDatasets';
import AdminEditDatasets from './components/admin/AdminEditDatasets';
import AdminUnderApprovalCourses from './components/admin/AdminUnderApprovalCourses';
import AdminUnderApprovalDatasets from './components/admin/AdminUnderApprovalDatasets';
import { LOGOUT } from './actions/types';


// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './App.css';
import { Outlet } from 'react-router-dom';


const App = () => {
  useEffect(() => {
    // check for token in SS when app first runs
    if (sessionStorage.tokens) {
      // if there is a token set axios headers for all requests
      setAuthToken(sessionStorage.tokens);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    //store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!sessionStorage.tokens) store.dispatch({ type: LOGOUT });
    });
  }, []);

  const CommonLayout = () => (
    <div className='common-container'>
      <Outlet />
    </div>
  );
  const AdminLayout = () => (
    <div className='admin-container'>
      <Outlet />
    </div>
  );

  // Route matches the url path with the page we want to load
  return (
    <Provider store={store}>
      <Router>
        <Navbar />
        <Alert />
        <Routes>
          <Route element={<CommonLayout />}>
            <Route path="/" element={<Landing />} />
            <Route path="register" element={<Register />} />
            <Route path="login" element={<Login />} />
            <Route path="reset_password" element={<ResetPassword />} />
            <Route path='course/:id' element={<ShowCourseDetails />} />
            <Route path='rehab/:id' element={<ShowRehabDetails />} />
            <Route
              path="upload"
              element={<PrivateRoute component={CreateNewResource} />}
            />
            <Route
              path="dashboard"
              element={<PrivateRoute component={Dashboard} />}
            />
            <Route
              path="edit-review/:id"
              element={<PrivateRoute component={EditReview} />}
            />
            <Route path="/*" element={<NotFound />} />
          </Route>
          <Route element={<AdminLayout />}>
            <Route
              path="/admin_dashboard"
              element={<AdminRoute component={AdminDashBoard} />}
            />
            <Route
              path="/admin_existing_course"
              element={<AdminRoute component={AdminExistingCourses} />}
            />
            <Route
              path="/admin_existing_dataset"
              element={<AdminRoute component={AdminExistingDatasets} />}
            />
            <Route
              path="/admin_edit_course/:id"
              element={<AdminRoute component={AdminEditCourses} />}
            />
            <Route
              path="/admin_edit_dataset/:id"
              element={<AdminRoute component={AdminEditDatasets} />}
            />
            <Route
              path="/admin_underApproval_course"
              element={<AdminRoute component={AdminUnderApprovalCourses} />}
            />
            <Route
              path="/admin_underApproval_dataset"
              element={<AdminRoute component={AdminUnderApprovalDatasets} />}
            />
          </Route>
        </Routes>

      </Router>
    </Provider>
  );
};

export default App;
