import React from "react";
import { Link } from 'react-router-dom';

const CourseCard = ({ course_item }) => {
  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className="course_card">
      <h4> {course_item.name} </h4>
      <div className="tag">
        <i>Course ·</i> {(course_item.courseLengthValue <= 1 ? (course_item.courseLengthScale === 'hours' ? course_item.courseLengthValue + " hour" : course_item.courseLengthValue + " month") : course_item.courseLengthValue + " " + course_item.courseLengthScale)}
      </div>
      <div className="level">
        <i>Level:</i> {capitalizeFirst((course_item.level === 'first_time_user' ? 'First-time user' : course_item.level))}
      </div>
      <div className="keyword">
        <i>Keywords:</i> {course_item.keywords.join(", ")}
      </div>


      <Link to={`/course/${course_item.id}`} className="trailer-btn">
        view details
      </Link>
    </div>
  );
};
export default CourseCard;

